import { AddNewVersion } from 'components/maps/fieldHistoryCard';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AppConstants } from 'utils/app.constants';
import CircleOutlinedDot from '../../../../public/dot.png';
import { ArchiveIcon } from '../archive.icon';
import * as Styled from './archived-version.style';
import { Neutral } from 'app/theme';

/**
 * In the backend, there isn't actually an 'archived version' of a field. Instead, there's a temporal gap between versions.
 * Despite its imprecision, we use the term 'Archived Version' here for clarity. This component represents the temporal gap as a version on the field's history page.
 */
export function ArchivedVersion({
  setShowSelectDateModal,
  date
}: {
  setShowSelectDateModal: React.Dispatch<React.SetStateAction<boolean>>;
  date: string;
}) {
  const { t } = useTranslation();

  return (
    <div className="timeline-row">
      <div className="sp-1">
        <div className="top-img">
          <AddNewVersion setShowSelectDateModal={setShowSelectDateModal} />
        </div>
        <div className="content" style={{ cursor: 'default' }}>
          <div className="line-div">
            <div className="line" />
            <div className="dot">
              <img
                src={CircleOutlinedDot}
                className="timeline-circle-icon"
                alt="timeline-circle-icon"
              />
            </div>
          </div>
          <Styled.DateContainer>
            <div>{moment(date).format(AppConstants.DATE_FORMATS.MMM_DD_YYYY)}</div>
            <Styled.FlexContainer>
              <ArchiveIcon fill={Neutral[50]} />
              <span className="archived-label">
                {t('archiving.field_history.archived_field_label')}
              </span>
            </Styled.FlexContainer>
          </Styled.DateContainer>
        </div>
      </div>
    </div>
  );
}
