/* eslint-disable no-param-reassign */
/**
 * i18n : https://react.i18next.com/latest/using-with-hooks
 */
import { defaultLanguage } from 'app.constant';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: defaultLanguage,
    interpolation: { escapeValue: false },
    backend: { loadPath: `/i18n/{{lng}}.json?hash=${Date.now()}` }
  });

export default i18n;
