import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

const MapContext = createContext<{
  mapInstance: mapboxgl.Map | null;
  setMapInstance: (map: mapboxgl.Map | null) => void;
} | null>(null);

export function MapProvider({ children }: PropsWithChildren<any>) {
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);
  const value = useMemo(() => ({ mapInstance, setMapInstance }), [mapInstance]);

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}

export function useMap() {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error('useMap must be used within a MapProvider');
  }
  return context.mapInstance;
}

export function useSetMap() {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error('useSetMap must be used within a MapProvider');
  }
  return context.setMapInstance;
}
