import {
  FETCH_DETECT_FIELDS_BOUNDRIES_ERROR,
  FETCH_DETECT_FIELDS_BOUNDRIES_LOADING,
  FETCH_DETECT_FIELDS_BOUNDRIES_SUCCESS,
  FETCH_FIELD_DETECTION_SOURCE_LIST_LOADING,
  FETCH_FIELD_DETECTION_SOURCE_LIST_SUCCESS,
  FETCH_FIELD_DETECTION_SOURCE_LIST_ERROR,
  FETCH_FIELD_DETECTION_TOWNSHIP_GRID_LOADING,
  FETCH_FIELD_DETECTION_TOWNSHIP_GRID_SUCCESS,
  SET_CANADA_TOWNSHIP_GRID_OFF_SUCCESS,
  FETCH_FIELD_DETECTION_TOWNSHIP_GRID_ERROR
} from '../actions/types';

export const initialState = {
  loading: false,
  error: null,
  data: [],
  providerName: null,
  isEmptyList: false,
  fieldList: [],
  canadaTownshipGridPopupDetails: {},
  isCanadaTownshipGrid: false,
  townshipGridError: false,
  controller: null
};

export const initialStateForFieldDetectSourceList = {
  isLoading: false,
  isError: false,
  fieldDetectionSourceListError: null,
  fieldDetectionSourceListData: []
};

export const detectFields = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DETECT_FIELDS_BOUNDRIES_LOADING:
      if (state.controller) state.controller.abort();
      return {
        ...state,
        controller: action.payload || null,
        loading: true
      };
    case FETCH_DETECT_FIELDS_BOUNDRIES_SUCCESS:
      return {
        ...state,
        data: action.payload.list,
        providerName: action.payload.providerName,
        isEmptyList: action.payload.isEmptyList.length === 0,
        fieldList: action.payload.fieldList,
        controller: null,
        loading: false
      };
    case FETCH_DETECT_FIELDS_BOUNDRIES_ERROR:
      return {
        ...state,
        error: action.error,
        controller: null,
        loading: false
      };
    case FETCH_FIELD_DETECTION_TOWNSHIP_GRID_LOADING:
      return {
        ...state,
        canadaTownshipGridPopupDetails: action.data,
        isCanadaTownshipGrid: true
      };
    case FETCH_FIELD_DETECTION_TOWNSHIP_GRID_SUCCESS:
      return {
        ...state,
        data: action.data.features,
        fieldList: action.data,
        controller: null,
        loading: false,
        townshipGridError: false,
        isCanadaTownshipGrid: true
      };
    case FETCH_FIELD_DETECTION_TOWNSHIP_GRID_ERROR:
      return {
        ...state,
        townshipGridError: true,
        controller: null,
        loading: false
      };
    case SET_CANADA_TOWNSHIP_GRID_OFF_SUCCESS:
      return {
        ...state,
        isCanadaTownshipGrid: false
      };
    default:
      return state;
  }
};

export const fieldDetectionSourceList = (
  state = initialStateForFieldDetectSourceList,
  { type, data, error }
) => {
  switch (type) {
    case FETCH_FIELD_DETECTION_SOURCE_LIST_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case FETCH_FIELD_DETECTION_SOURCE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fieldDetectionSourceListData: data
      };

    case FETCH_FIELD_DETECTION_SOURCE_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        fieldDetectionSourceListError: error
      };

    default:
      return state;
  }
};
