import { WarningOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';

export const ConfirmPopup = ({
  placement = 'rightBottom',
  title,
  onConfirm,
  okText,
  cancelText,
  children
}: any) => {
  return (
    <Popconfirm
      placement={placement}
      title={title || 'This action cannot be undone. Continue?'}
      icon={<WarningOutlined style={{ fontSize: '19px', top: '7px', color: '#E99921' }} />}
      onConfirm={onConfirm}
      okText={okText || 'Yes'}
      okButtonProps={{ size: 'middle', color: 'red' }}
      cancelButtonProps={{ size: 'middle' }}
      cancelText={cancelText || 'No'}
    >
      {children}
    </Popconfirm>
  );
};
