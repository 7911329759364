import {
  FETCH_PLAN_BY_ID_SUCCESS,
  FETCH_PLAN_BY_ID_ERROR,
  FETCH_PLAN_BY_ID_LOADING
} from '../actions/types';

const initialState = {
  loading: false,
  error: false,
  success: false,
  data: null
};

export const plans = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLAN_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        success: false
      };
    case FETCH_PLAN_BY_ID_SUCCESS:
      return {
        ...state,
        data: action.payload,
        success: true,
        error: false,
        loading: false
      };
    case FETCH_PLAN_BY_ID_ERROR:
      return {
        ...state,
        error: true,
        success: false,
        loading: false
      };
    default:
      return state;
  }
};
