import { Neutral } from 'app/theme';
import styled from 'styled-components';

export const FieldInfo = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  color: ${Neutral[60]};
  direction: rtl;
  text-align: left;
`;

export const FieldArea = styled.div`
  font-size: 12px;
  direction: ltr;

  .archived-tag {
    color: ${Neutral[40]};
    margin-left: 4px;

    ::before {
      content: ' ';
      width: 8px;
      height: 8px;
      border-radius: 4px;
      display: inline-block;
      margin-right: 4px;
      background-color: ${Neutral[40]};
    }
  }
`;
