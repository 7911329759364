export function ArchiveIcon({ fill = 'white' }: { fill?: string }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3625 2.6625L15.405 3.9225C15.6225 4.1775 15.75 4.515 15.75 4.875V14.25C15.75 15.075 15.075 15.75 14.25 15.75H3.75C2.925 15.75 2.25 15.075 2.25 14.25V4.875C2.25 4.515 2.3775 4.1775 2.595 3.9225L3.63 2.6625C3.84 2.4075 4.1475 2.25 4.5 2.25H13.5C13.8525 2.25 14.16 2.4075 14.3625 2.6625ZM13.32 3.75H4.68L4.08 4.4775H13.9275L13.32 3.75ZM3.75 14.25V6H14.25V14.25H3.75ZM7.9125 7.5H10.0875V9.75H12L9 12.75L6 9.75H7.9125V7.5Z"
        fill={fill}
      />
    </svg>
  );
}
