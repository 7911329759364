import { Blue, Neutral } from 'app/theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
`;
export const FieldListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 20px 24px;
  background-color: ${Neutral[0]};

  .ant-input-affix-wrapper {
    border-radius: 4px;
    border-color: ${Neutral[30]} !important;
    font-size: 14px;
    line-height: 25px;
  }
`;
export const MapContainer = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 20px;
`;
export const Help = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${Blue[60]};
`;
export const CheckAllHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid ${Neutral[10]};
  padding-bottom: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 16px;

  input {
    margin-right: 4px;
  }

  .sort-icon-container {
    margin-top: -4px;
  }
`;
export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  gap: 24px;
  font-weight: bold;
`;

export const EmptyStateSearchContainer = styled.div`
  text-align: center;
  margin-top: 24px;

  > div {
    margin-top: 8px;
    > p {
      margin-bottom: 4px;
    }
  }
`;

export const StyledCheckbox = styled.input`
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: relative;
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid ${Neutral[10]};
  padding-right: 12px;
  height: 52px;
`;
