import { schema } from 'normalizr';

// Property schema details
export const property = new schema.Entity('properties');
export const propertiesSchema = new schema.Array(property);

// Seasons schema details
export const season = new schema.Entity('seasons');
export const seasonsSchema = new schema.Array(season);

//Season Fields details
const seasonFields = new schema.Entity('seasonFields', undefined, {
  idAttribute: (value) => `${value.id}:${value.season_id}`
});
export const seasonFieldsSchema = new schema.Array(seasonFields);

const fields = new schema.Entity('fields');

export const FieldsSchema = new schema.Array(fields);

// Orgs schema details
export const orgs = new schema.Entity('orgs');
export const orgsListSchema = new schema.Array(orgs);

// propertiesBySeasonId schema
const propertiesBySeasonId = new schema.Entity('propertiesBySeasonId', undefined, {
  idAttribute: (value) => `${value.id}:${value.season_id}`
});
export const propertiesBySeasonIdSchema = new schema.Array(propertiesBySeasonId);

// crops schema
const crops = new schema.Entity('crops');
export const cropsListSchema = new schema.Array(crops);
