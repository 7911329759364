import { showNotification } from 'components/common/showNotification';
import { request } from 'utils/axios';
import { getHeaders } from 'utils/commonMethods';
import i18n from '../../../i18n';
import {
  ArchiveErrorModel,
  FIELD_HAS_ACTIVE_SEASON_ERROR_CODE,
  FIELD_HAS_OVERLAP_ERROR_CODE,
  FieldModel,
  RegionModel,
  RegionNameMapViewModel,
  UnarchiveErrorModel
} from './archiving.model';
import { getRegionChildrenName } from './archiving.util';

const getOptions = () => ({ headers: getHeaders()?.common });

export function getPropertyRegionNames(farmId: string) {
  return request
    .get<RegionModel>(`/v2/properties/${farmId}/region-tree`, getOptions())
    .then((response) => response.data.children_region)
    .then(getRegionChildrenName)
    .catch(() => {
      return {} as RegionNameMapViewModel;
    });
}

export function getArchivedFields(farmId: string) {
  return request
    .get<{ areas: FieldModel[] }>(`/v2/properties/${farmId}/fields/archived`, getOptions())
    .then((response) =>
      response.data.areas.map((area) => ({
        ...area,
        geometry: JSON.parse((area.geometry as unknown as string) || '{}')?.geometry || {}
      }))
    )
    .catch(() => {
      showNotification(
        'failed',
        i18n.t('archiving.notifications.unexpected_error'),
        i18n.t('archiving.notifications.unexpected_error_get_archive_description')
      );
      return [];
    });
}

interface WithCodeModel {
  code: string;
}
type ExpectedError<T> = { response: { data: T } };
const isExpectedError = <T extends WithCodeModel>(
  err: unknown,
  expectedCode: string
): err is ExpectedError<T> => (err as ExpectedError<T>)?.response?.data?.code === expectedCode;

export function archiveFields(ids: string[]) {
  return request
    .put(`/v2/fields/archive/ids`, { ids }, getOptions())
    .then(() => ({ success: true }) as const)
    .catch((err) => {
      if (isExpectedError<ArchiveErrorModel>(err, FIELD_HAS_ACTIVE_SEASON_ERROR_CODE)) {
        return { success: false, activeSeasonError: err.response.data };
      } else {
        showNotification(
          'failed',
          i18n.t('archiving.notifications.unexpected_error'),
          i18n.t('archiving.notifications.unexpected_error_archive_description', {
            count: ids.length
          })
        );
        return { success: false };
      }
    });
}

export function unarchiveFields(id: string) {
  return request
    .put(`/v2/fields/${id}/unarchive`, {}, getOptions())
    .then(() => ({ success: true }) as const)
    .catch((err) => {
      if (isExpectedError<UnarchiveErrorModel>(err, FIELD_HAS_OVERLAP_ERROR_CODE)) {
        return { success: false, overlapError: err.response.data };
      } else {
        showNotification(
          'failed',
          i18n.t('archiving.notifications.unexpected_error'),
          i18n.t('archiving.notifications.unexpected_error_unarchive_description')
        );
        return { success: false };
      }
    });
}
