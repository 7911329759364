import isEmpty from 'lodash/isEmpty';

const IS_CREATE_PROPERTY_SESSION_ACTIVE = 'IS_CREATE_PROPERTY_SESSION_ACTIVE';

export const isCreatePropertySessionActive = () => {
  if (
    sessionStorage.getItem(IS_CREATE_PROPERTY_SESSION_ACTIVE) === 'false' ||
    isEmpty(sessionStorage.getItem(IS_CREATE_PROPERTY_SESSION_ACTIVE))
  ) {
    return false;
  } else {
    return true;
  }
};

export const setIsCreatePropertySessionActive = (value) => {
  sessionStorage.setItem(IS_CREATE_PROPERTY_SESSION_ACTIVE, value);
};
