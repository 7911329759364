import omit from 'lodash/omit';
import {
  DELETE_SEASON_FIELD,
  RESET_ADD_SEASON_FIELD,
  SET_ACTIVE_SEASON_BY_ID_ERROR,
  SET_ACTIVE_SEASON_BY_ID_LOADING,
  SET_ACTIVE_SEASON_BY_ID_SUCCESS,
  SET_SEASON_FIELDS_ERROR,
  SET_SEASON_FIELDS_LOADING,
  SET_SEASON_FIELDS_SUCCESS
} from '../actions/types';

const initialState = {
  data: {
    entities: {},
    result: []
  },
  loading: false,
  error: null,
  addSeasonFieldLoading: false,
  addSeasonFieldError: null,
  addSeasonFieldSuccess: false,
  activeSeasonByFieldIdLoading: false,
  activeSeasonByFieldIdError: null,
  activeSeasonByFieldId: {
    entities: {},
    result: []
  }
};

export const seasonFields = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEASON_FIELDS_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_SEASON_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: { ...state.data, ...action.payload }
      };
    case SET_SEASON_FIELDS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case SET_ACTIVE_SEASON_BY_ID_SUCCESS:
      return {
        ...state,
        activeSeasonByFieldId: { ...action.payload },
        activeSeasonByFieldIdError: false,
        activeSeasonByFieldIdLoading: false
      };
    case SET_ACTIVE_SEASON_BY_ID_LOADING:
      return {
        ...state,
        activeSeasonByFieldIdLoading: true,
        activeSeasonByFieldIdError: null
      };
    case SET_ACTIVE_SEASON_BY_ID_ERROR:
      return {
        ...state,
        activeSeasonByFieldIdLoading: false,
        activeSeasonByFieldIdError: action.error
      };
    case RESET_ADD_SEASON_FIELD:
      return {
        ...state,
        addSeasonFieldLoading: false,
        addSeasonFieldError: null,
        addSeasonFieldSuccess: true
      };
    case DELETE_SEASON_FIELD:
      const itemIdsToDelete = state.data.result.filter((id) =>
        id.includes(action.payload.seasonId)
      );
      const itemIdsToStore = state.data.result.filter(
        (id) => !id.includes(action.payload.seasonId)
      );
      const deletedSeasonFields = omit(state.data.entities.seasonFields, [...itemIdsToDelete]);
      return {
        ...state,
        data: {
          entities: { seasonFields: deletedSeasonFields },
          result: itemIdsToStore
        }
      };
    default:
      return state;
  }
};
