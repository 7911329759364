const mapInit = () => {
  const script = document.createElement('script');
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  script.src = `https://maps.googleapis.com/maps/api/js?v=3.46&key=${apiKey}&libraries=places&callback=initAutocomplete`;
  script.async = 'true';
  script.defer = 'true';
  document.body.appendChild(script);

  if (window.initAutocomplete === undefined) {
    window.initAutocomplete = () => null;
  }
};

mapInit();
