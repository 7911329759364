import { FieldModel, RegionModel, RegionNameMapViewModel } from './archiving.model';

interface Version {
  valid_since: string;
  valid_until: string | null;
}
/**
 * Check if there is a temporal gap between the version at `currentIndex` and the previous version.
 * @param history Array of field versions, sorted in descending order (current version = index 0)
 * @param currentIndex Index to check for temporal gaps
 * @returns true if there is a temporal gap, false otherwise
 */
export function checkTemporalGap(history: Version[], currentIndex: number) {
  const currentVersion = history?.[currentIndex];
  const prevVersion = history?.[currentIndex + 1];

  if (
    typeof currentVersion?.valid_since === 'string' &&
    typeof prevVersion?.valid_until === 'string'
  ) {
    const validUntil = new Date(prevVersion.valid_until);

    // To detect a archive, we check if the valid since of the current version is after the valid until (+ 1 day) of previous version
    validUntil.setDate(validUntil.getDate() + 1);
    return new Date(currentVersion.valid_since) > validUntil;
  }
  return false;
}

export function getFieldWithParentName(field: FieldModel, regionNames: RegionNameMapViewModel) {
  const parentName = regionNames[field.parent_region_id];
  const name = (parentName ? parentName + ' / ' : '') + field.name;

  return {
    ...field,
    name
  };
}

export function getRegionChildrenName(children: RegionModel[], parentName: string = '') {
  const regionNames: RegionNameMapViewModel = {};
  for (let region of children) {
    const name = (parentName ? parentName + ' / ' : '') + region.name;
    regionNames[region.id] = name;
    Object.assign(regionNames, getRegionChildrenName(region.children_region, name));
  }
  return regionNames;
}
