const CONVERSION_RATE = 2.471;

export const getAreaByUnit = (area, unit) => {
  if (area === undefined) {
    return '--';
  }
  const value = Number(area);
  switch (unit) {
    case 'IMPERIAL':
      return (value * CONVERSION_RATE).toFixed(2) || 0;
    case 'METRIC':
      return value.toFixed(2) || 0;
    default:
      return value.toFixed(2) || 0;
  }
};

export const getUnitText = (unit) => {
  switch (unit) {
    case 'IMPERIAL':
      return 'ac';
    case 'METRIC':
      return 'ha';
    default:
      return 'ha';
  }
};

export const convertAcreToHectare = (acre) => {
  return acre / CONVERSION_RATE;
};
