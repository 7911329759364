import { request } from '../../utils/axios';
import { getHeaders } from '../../utils/commonMethods';
import {
  GET_ACCOUNT_ROLES_LOADING,
  GET_ACCOUNT_ROLES_SUCCESS,
  GET_ACCOUNT_ROLES_ERROR,
  EDIT_ACCOUNT_ROLES_LOADING,
  EDIT_ACCOUNT_ROLES_SUCCESS,
  EDIT_ACCOUNT_ROLES_ERROR,
  VERIFY_PERMISSIONS_LOADING,
  VERIFY_PERMISSIONS_SUCCESS,
  VERIFY_PERMISSIONS_ERROR,
  GET_ROLES_BY_CONTEXT_LOADING,
  GET_ROLES_BY_CONTEXT_SUCCESS,
  GET_ROLES_BY_CONTEXT_ERROR
} from './types';

export const RBAC_ROLES_UPDATES_CHUNK_SIZE = 100;

function verifyPermissionsLoading() {
  return {
    type: VERIFY_PERMISSIONS_LOADING
  };
}
function verifyPermissionsSuccess(value) {
  return {
    type: VERIFY_PERMISSIONS_SUCCESS,
    payload: value
  };
}
function verifyPermissionsError(error) {
  return {
    type: VERIFY_PERMISSIONS_ERROR,
    error
  };
}

function editAccountRolesLoading() {
  return {
    type: EDIT_ACCOUNT_ROLES_LOADING
  };
}
function editAccountRolesSuccess(value) {
  return {
    type: EDIT_ACCOUNT_ROLES_SUCCESS,
    payload: value
  };
}
function editAccountRolesError(error) {
  return {
    type: EDIT_ACCOUNT_ROLES_ERROR,
    error
  };
}

function fetchAccountRolesLoading() {
  return {
    type: GET_ACCOUNT_ROLES_LOADING
  };
}
function fetchAccountRolesSuccess(value) {
  return {
    type: GET_ACCOUNT_ROLES_SUCCESS,
    payload: value
  };
}
function fetchAccountRolesError(error) {
  return {
    type: GET_ACCOUNT_ROLES_ERROR,
    error
  };
}
function getRolesByContextLoading() {
  return {
    type: GET_ROLES_BY_CONTEXT_LOADING
  };
}
function getRolesByContextSuccess(value) {
  return {
    type: GET_ROLES_BY_CONTEXT_SUCCESS,
    payload: value
  };
}
function getRolesByContextError(error) {
  return {
    type: GET_ROLES_BY_CONTEXT_ERROR,
    error
  };
}

export const getRolesByContext = (context) => async (dispatch) => {
  dispatch(getRolesByContextLoading());
  try {
    const res = await request.get(`v2/roles/${context}`, {
      headers: getHeaders(),
      useCache: false
    });
    if (res && res.data) {
      dispatch(getRolesByContextSuccess(res.data));
    }
    return res;
  } catch (error) {
    dispatch(getRolesByContextError());
    throw error;
  }
};

export const fetchAccountRoles = (accountId) => async (dispatch) => {
  dispatch(fetchAccountRolesLoading());
  try {
    const res = await request.get(`/v2/accounts/${accountId}/roles`, {
      headers: getHeaders(),
      useCache: false
    });
    if (res && res.data && res.data.content) {
      dispatch(fetchAccountRolesSuccess(res.data.content));
    }
    return res.data.content;
  } catch (error) {
    dispatch(fetchAccountRolesError(error));
    throw error;
  }
};

const editRolesUpdates = (accountId, rolesUpdatesDTO) => async (dispatch) => {
  const res = await request.post(`/v2/accounts/${accountId}/roles/updates`, rolesUpdatesDTO, {
    headers: getHeaders(),
    useCache: false
  });
  if (res?.data) {
    dispatch(editAccountRolesSuccess(res.data));
  }
  return res;
};

const batchUpdatesWithChunks = (accountId, updates) => async (dispatch) => {
  const rolesToAdd = updates.find((update) => update.operation === 'ADD')?.roles;
  const rolesToRemove = updates.find((update) => update.operation === 'REMOVE')?.roles;
  const rolesToAddCount = rolesToAdd?.length;
  const rolesToRemoveCount = rolesToRemove?.length;
  const editResult = [];
  if (rolesToAddCount && rolesToAddCount > 0) {
    for (
      let rolesIndex = 0;
      rolesIndex < rolesToAddCount;
      rolesIndex += RBAC_ROLES_UPDATES_CHUNK_SIZE
    ) {
      const newPayload = { updates: [{ operation: 'ADD' }] };
      newPayload.updates[0].roles = rolesToAdd.slice(
        rolesIndex,
        rolesIndex + RBAC_ROLES_UPDATES_CHUNK_SIZE
      );
      const editRolesResponse = await dispatch(editRolesUpdates(accountId, newPayload));
      editResult.push(editRolesResponse);
    }
  }
  if (rolesToRemoveCount && rolesToRemoveCount > 0) {
    for (
      let rolesIndex = 0;
      rolesIndex < rolesToRemoveCount;
      rolesIndex += RBAC_ROLES_UPDATES_CHUNK_SIZE
    ) {
      const newPayload = { updates: [{ operation: 'REMOVE' }] };
      newPayload.updates[0].roles = rolesToRemove.slice(
        rolesIndex,
        rolesIndex + RBAC_ROLES_UPDATES_CHUNK_SIZE
      );
      const editRolesResponse = await dispatch(editRolesUpdates(accountId, newPayload));
      editResult.push(editRolesResponse);
    }
  }
  const resultWithError = editResult.find((result) => result.status !== 201);
  return resultWithError ?? editResult[0];
};

const isRolesCountOutOfLimit = (count) => count && count > RBAC_ROLES_UPDATES_CHUNK_SIZE;

export const editAccountRoles = (accountId, rolesUpdatesDTO) => async (dispatch) => {
  dispatch(editAccountRolesLoading());
  try {
    const { updates } = rolesUpdatesDTO;
    const rolesToAddCount = updates.find((update) => update.operation === 'ADD')?.roles.length;
    const rolesToRemoveCount = updates.find((update) => update.operation === 'REMOVE')?.roles
      .length;
    const isUpdatesOutOfRBACLimit =
      isRolesCountOutOfLimit(rolesToAddCount) || isRolesCountOutOfLimit(rolesToRemoveCount);
    if (isUpdatesOutOfRBACLimit) {
      return dispatch(batchUpdatesWithChunks(accountId, updates));
    }
    return dispatch(editRolesUpdates(accountId, rolesUpdatesDTO));
  } catch (error) {
    dispatch(editAccountRolesError(error));
    throw error;
  }
};

export const verifyPermissions = (params) => async (dispatch) => {
  dispatch(verifyPermissionsLoading());
  try {
    const res = await request.post(`/v2/auth/verify`, params, {
      headers: getHeaders(),
      useCache: false
    });
    if (res && res.data) {
      dispatch(verifyPermissionsSuccess(res.data));
    }
    return res.data;
  } catch (error) {
    dispatch(verifyPermissionsError(error));
    throw error;
  }
};
