import {
  FETCH_PROXY_INVITATION_ROLES_ERROR,
  FETCH_PROXY_INVITATION_ROLES_LOADING,
  FETCH_PROXY_INVITATION_ROLES_SUCCESS
} from '../actions/types';

const initialState = {
  loading: false,
  hasLoaded: false,
  error: null,
  success: false,
  data: []
};

export const proxyApi = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_PROXY_INVITATION_ROLES_LOADING:
      return {
        ...state,
        loading: true,
        hasLoaded: false
      };
    case FETCH_PROXY_INVITATION_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        hasLoaded: true,
        data: action.payload,
        error: null
      };
    case FETCH_PROXY_INVITATION_ROLES_ERROR:
      return {
        ...state,
        loading: false,
        hasLoaded: true,
        error: action.error
      };
    default:
      return state;
  }
};
