export interface FieldModel {
  id: string;
  parent_region_id: string;
  name: string;
  calculated_area: number;
  declared_area: number;
  geometry: GeoJSON.Polygon;
}

export const FIELD_HAS_ACTIVE_SEASON_ERROR_CODE = 'EFLD020';
export interface ArchiveErrorModel {
  code: typeof FIELD_HAS_ACTIVE_SEASON_ERROR_CODE;
  errors: {
    field_id: string;
    field_name: string;
    season: {
      season_name: string;
    };
  }[];
}
export const FIELD_HAS_OVERLAP_ERROR_CODE = 'EGEO005';
export interface UnarchiveErrorModel {
  code: typeof FIELD_HAS_OVERLAP_ERROR_CODE;
  errors: {
    field: {
      id: string;
      name: string;
      calculated_area: number;
      declared_area: number;
    };
    fields_overlapped: {
      id: string;
      name: string;
      calculated_area: number;
      declared_area: number;
    }[];
  }[];
}

export interface RegionModel {
  id: string;
  parent_id: string | null;
  name: string;
  children_region: RegionModel[];
}
export interface RegionNameMapViewModel {
  [regionId: string]: string;
}
