export default function MenuCollapse(props: { className: string }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 5C5.44772 5 5 5.44772 5 6C5 6.55228 5.44772 7 6 7H18C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5H6ZM3.00025 15.0002C3.25625 15.0002 3.51225 14.9023 3.70725 14.7073C4.09825 14.3162 4.09825 13.6842 3.70725 13.2933L2.41425 12.0002L3.70725 10.7073C4.09825 10.3162 4.09825 9.68425 3.70725 9.29325C3.31625 8.90225 2.68425 8.90225 2.29325 9.29325L0.29325 11.2933C-0.09775 11.6842 -0.09775 12.3163 0.29325 12.7073L2.29325 14.7073C2.48825 14.9023 2.74425 15.0002 3.00025 15.0002ZM5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6C5.44772 13 5 12.5523 5 12ZM6 17C5.44772 17 5 17.4477 5 18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17H6Z"
      />
    </svg>
  );
}
