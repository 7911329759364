import { Col, Row, Tooltip } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import CenterMap from '../../public/property_focus.svg';
import { translationWrapper } from '../../utils/commonMethods';

interface Props {
  handleLocateProperty: () => any;
}

export const MyPropertyMyLocationButtons: React.FC<Props> = ({ handleLocateProperty }) => {
  const { t } = translationWrapper(useTranslation(), false);
  return (
    <Row className="locate-property-on-map-btn">
      <Col span={24}>
        <Tooltip
          overlayClassName="tooltip select-date-tooltip"
          zIndex={9999999}
          placement="left"
          title={t(`global.map.tooltip.center_map`)}
        >
          <img alt="Undo Action" src={CenterMap} onClick={handleLocateProperty} />
        </Tooltip>
      </Col>
    </Row>
  );
};
