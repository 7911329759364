export const Blue = {
  10: '#EAF6FF',
  20: '#C6E6FF',
  30: '#82CFFF',
  40: '#31B4F2',
  50: '#0092E4',
  60: '#0071CD',
  70: '#005693',
  80: '#003B69',
  90: '#002747',
  100: '#00172D'
} as const;

export const Green = {
  10: '#DFFBE8',
  20: '#C3EAD1',
  30: '#90D6AA',
  40: '#5EBB7F',
  50: '#19A04B',
  60: '#14803C',
  70: '#0C612C',
  80: '#06431C',
  90: '#032D10',
  100: '#011B07'
} as const;

export const Red = {
  10: '#FFF1F1',
  20: '#FFD9D9',
  30: '#FFB3B3',
  40: '#F98989',
  50: '#EB4B4B',
  60: '#CF3537',
  70: '#9C2628',
  80: '#70191A',
  90: '#4C0E0F',
  100: '#300606'
} as const;

export const Yellow = {
  10: '#FFF3DD',
  20: '#FFE4AE',
  30: '#F0C355',
  40: '#E99921',
  50: '#C17E19',
  60: '#9A6412',
  70: '#744A0B',
  80: '#523305',
  90: '#372102',
  100: '#221201'
} as const;

export const Neutral = {
  0: '#FFFFFF',
  10: '#F3F4F6',
  20: '#DFE2E7',
  30: '#C2C7D0',
  40: '#A3A9B9',
  50: '#868CA2',
  60: '#696F88',
  70: '#4D5165',
  80: '#363948',
  90: '#232630',
  100: '#14151C'
} as const;

export const Brand = {
  green: '#73DC78'
} as const;
