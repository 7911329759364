import { EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { StyledModal } from 'app/common';
import { ArchiveIcon } from 'app/fields/archiving/archive.icon';
import {
  ArchivingModalsEnum,
  OverlappingFieldViewModel,
  useArchivingInternal
} from 'app/fields/archiving/archiving-internal.provider';
import { Blue, Neutral } from 'app/theme';
import { Trans, useTranslation } from 'react-i18next';
import { history, URL_CONSTANTS } from 'utils/history';
import * as CommonStyled from '../../archiving.style';
import * as Styled from './unarchive-error.style';

export function UnarchiveError() {
  // We can't use useParams here as we're not inside the router provider
  const pathname = window.location.pathname;
  const orgId = pathname.split('/')[3];
  const farmId = pathname.split('/')[5];
  const { t } = useTranslation();

  const {
    selectedFieldsIds,
    overlappingFields,
    modals: { [ArchivingModalsEnum.UNARCHIVE_ERROR]: open },
    triggerModal
  } = useArchivingInternal();

  const selectedFieldId = selectedFieldsIds[0];

  const onClose = () => {
    triggerModal(ArchivingModalsEnum.UNARCHIVE_ERROR);
  };

  const columns = [
    {
      title: t('Field'),
      dataIndex: 'name',
      key: 'name',
      render: (_: unknown, record: OverlappingFieldViewModel) => (
        <CommonStyled.FieldInfo>
          <span>{record.name}</span>
          <CommonStyled.FieldArea>
            {record.area && <span>{record.area.toFixed(2)} ha</span>}
          </CommonStyled.FieldArea>
        </CommonStyled.FieldInfo>
      )
    },
    {
      title: t('Status'),
      dataIndex: '',
      key: 'x',
      render: (_: unknown, record: OverlappingFieldViewModel) => {
        const isSelected = record.id === selectedFieldId;
        return (
          <Styled.Tag
            style={{
              backgroundColor: isSelected ? Neutral[10] : Blue[20],
              color: isSelected ? Neutral[70] : Blue[70]
            }}
          >
            {isSelected && <ArchiveIcon fill={Neutral[70]} />}
            <span>
              {t(
                isSelected
                  ? 'archiving.notifications.unarchive_error.archived_tag'
                  : 'archiving.notifications.unarchive_error.active_tag'
              )}
            </span>
          </Styled.Tag>
        );
      }
    },
    {
      title: t('Action'),
      dataIndex: '',
      key: 'y',
      render: (_: unknown, record: OverlappingFieldViewModel) => {
        const isSelected = record.id === selectedFieldId;

        if (isSelected) return null;

        return (
          <Styled.EditField
            href={URL_CONSTANTS.EDIT_FIELD_DRAW({ orgId, farmId, fieldId: selectedFieldId })}
            role="button"
            aria-label={
              t('archiving.notifications.unarchive_error.edit_field_label', {
                name: record.name
              }) as string
            }
            onClick={(ev) => {
              ev.preventDefault();

              onClose();
              history.push(
                URL_CONSTANTS.EDIT_FIELD_DRAW({ orgId, farmId, fieldId: selectedFieldId })
              );
            }}
          >
            <EditOutlined />
            <span>{t('Edit field')}</span>
          </Styled.EditField>
        );
      }
    }
  ];

  const fieldName = overlappingFields?.[0]?.name;

  return (
    <StyledModal
      open={open}
      showClose={true}
      error={true}
      width={950}
      onClose={onClose}
      title={t('archiving.notifications.unarchive_error.title')}
      ok={{
        onClick: onClose,
        text: t('common.button.cancel')
      }}
    >
      <div style={{ marginTop: 32 }}>
        <Trans key={'archiving.notifications.unarchive_error.description'} values={{ fieldName }}>
          The <b>{fieldName}</b> that you are trying to unarchive conflicts with active fields. Edit
          the geometry of the active fields to unarchive the <b>{fieldName}</b>.
        </Trans>

        <Table
          dataSource={overlappingFields}
          rowKey={'id'}
          columns={columns}
          style={{ margin: '16px 0' }}
          pagination={false}
          bordered
        />
      </div>
    </StyledModal>
  );
}
