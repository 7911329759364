import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import { Green, Neutral, Red, Yellow } from 'app/theme';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 16px;
  margin: 0;
`;

export const WarningIcon = styled(WarningOutlined)<{ $error?: boolean }>`
  font-size: 24px;
  color: ${({ $error }) => ($error ? Red[50] : Yellow[40])};
`;

export const CloseIcon = styled(CloseOutlined)`
  cursor: 'pointer';
  transition: opacity 300ms ease-in-out;

  &:hover {
    opacity: 0.6;
  }
`;

export const Btn = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  border-radius: 4px;
  background-color: ${Neutral[0]};
  color: ${Neutral[100]};
  border: 1px solid ${Neutral[30]};
  outline: none;
  transition: all 300ms ease-in-out;
  cursor: pointer;

  &:hover:not(:disabled) {
    border-color: ${Neutral[100]};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

export const OKBtn = styled(Btn)`
  background-color: ${Green[60]};
  border-color: ${Green[60]};
  color: ${Neutral[0]};
  outline: none;

  &:hover:not(:disabled) {
    background-color: ${Green[50]};
    border-color: ${Green[50]};
  }
`;
