import { SET_LAUNCHYDARKLY_FLAGS } from '../actions/types';

const initialState = {
  flags: null
};

export const launchDarkly = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAUNCHYDARKLY_FLAGS:
      return {
        ...state,
        flags: action.payload
      };

    default:
      return state;
  }
};
