import { useEffect, useState } from 'react';
import { useArchiving } from '../archiving.provider';

export enum SortDirectionEnum {
  asc = 'asc',
  desc = 'desc'
}
const sortFields = (dir: SortDirectionEnum) => (field1: any, field2: any) =>
  field1.name.localeCompare(field2.name) * (dir === SortDirectionEnum.desc ? -1 : 1);

export function useArchivedFields() {
  const { fields, loadingRegionNames, loading, fetchArchivedFields } = useArchiving();
  const [sortDirection, setSortDirection] = useState<SortDirectionEnum>(SortDirectionEnum.asc);
  const [query, setQuery] = useState('');

  const onSearch = ({ target: { value: query } }: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(query);
  };

  useEffect(() => {
    fetchArchivedFields(true);
  }, [fetchArchivedFields]);

  const filteredFields = fields
    .filter((value) => {
      return value.name.toLocaleLowerCase().includes(query.toLocaleLowerCase());
    })
    .sort(sortFields(sortDirection));

  return {
    fields,
    loadingRegionNames,
    loading,
    filteredFields,
    sortDirection,
    query,
    toggleSortDir: () =>
      setSortDirection(
        sortDirection === SortDirectionEnum.asc ? SortDirectionEnum.desc : SortDirectionEnum.asc
      ),
    onSearch
  };
}
