import get from 'lodash/get';

declare global {
  interface Window {
    google: any;
  }
}

const getDetailsFromIPAPI = () => {
  const details = sessionStorage.getItem('ipapi-details');
  if (details) {
    return JSON.parse(details);
  }
  return {};
};

export const getLocationFromBrowser = () => {
  try {
    const result: any = getDetailsFromIPAPI();
    return {
      latitude: result.latitude,
      longitude: result.longitude,
      country_name: result.country_name,
      country_code: result.country_code && result.country_code.toLowerCase()
    };
  } catch (e) {
    return {};
  }
};

/**
 * Moved this code to required file as this is not looks like common code
 * and I was facing problem for eslint
 * interface IGoogleLibraryAPI {
  maps: {
    Geocoder: any;
  };
}
const getGoogleLibrary = (): IGoogleLibraryAPI => window.google;
export const isGoogleLibraryLoaded = () => !!getGoogleLibrary();
 */

export async function getAddress(addrComponents: any[]) {
  const address = { country: '', state: '' };
  // eslint-disable-next-line no-unused-expressions
  if (addrComponents) {
    addrComponents.forEach((addrComponent) => {
      if (get(addrComponent, 'types[0]') === 'country') {
        address.country = addrComponent.short_name;
      } else if (get(addrComponent, 'types[0]') === 'administrative_area_level_1') {
        address.state = addrComponent.long_name;
      }
    });
  }
  return address;
}
