export default function UploadFileEmpty() {
  return (
    <svg
      width="129"
      height="94"
      viewBox="0 0 129 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.13296 5.65296V17.9395L3.84071 17.8017V4.35924C3.84071 2.23808 5.56026 0.518533 7.68143 0.518533H28.8711C30.5589 0.518533 32.0489 1.62042 32.5433 3.23419L34.1823 8.58402L33.8224 18.8195L31.2875 4.45311C30.7693 2.87728 29.2979 1.81225 27.639 1.81225H8.97367C6.85251 1.81225 5.13296 3.53179 5.13296 5.65296Z"
        fill="#DFE2E7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.32236 4.35921C3.32236 1.95168 5.27405 0 7.68158 0H28.8712C30.7869 0 32.478 1.25064 33.0392 3.08227L35.3345 18.4354L39.5593 19.9462L29.5735 23.4283C29.1252 22.0652 27.5517 21.508 26.1168 21.508L23.8124 20.7398C21.9776 20.7398 10.754 16.4331 10.754 18.2679L5.65161 18.5161L3.32236 18.2679V4.35921ZM7.68158 1.03699C5.84677 1.03699 4.35936 2.5244 4.35936 4.35921V17.3355L4.61462 17.3627V5.65292C4.61462 3.2454 6.5663 1.29371 8.97382 1.29371H27.6392C29.522 1.29371 31.192 2.50252 31.7802 4.29109L33.0215 8.06549H33.4813L32.0477 3.38603C31.62 1.99012 30.3312 1.03699 28.8712 1.03699H7.68158Z"
        fill="#DFE2E7"
      />
      <path
        d="M118.755 12.5662V18.5699L120.048 18.6564V11.2725C120.048 9.15136 118.328 7.43181 116.207 7.43181H33.8224C33.2463 7.43181 32.9576 8.1282 33.3648 8.53581C33.4861 8.65727 33.6507 8.72552 33.8224 8.72552H114.915C117.036 8.72552 118.755 10.4451 118.755 12.5662Z"
        fill="#DFE2E7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M120.432 11.2725C120.432 8.93924 118.54 7.04774 116.207 7.04774H33.8224C32.9043 7.04774 32.4442 8.15762 33.093 8.80723C33.2864 9.00082 33.481 18.4354 33.7547 18.4354L98.3222 23.0443C100.231 23.0443 113.685 17.6786 113.685 19.5876L118.371 18.9291L120.432 19.067V11.2725ZM116.207 7.81588C118.116 7.81588 119.664 9.36347 119.664 11.2725V18.2457L119.14 18.2107V12.5662C119.14 10.233 117.248 8.34145 114.915 8.34145H33.8224C33.7527 8.34145 33.6858 8.31373 33.6365 8.26438C33.4711 8.09879 33.5884 7.81588 33.8224 7.81588H116.207Z"
        fill="#DFE2E7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 19.9462C0 18.5497 1.41145 17.4177 3.15256 17.4177H119.75C121.491 17.4177 122.903 18.5497 122.903 19.9462L119.062 87.0945C119.062 88.491 117.651 89.623 115.909 89.623H6.99327C5.25215 89.623 3.84071 88.4909 3.84071 87.0945L0 19.9462Z"
        fill="#DFE2E7"
      />
      <path
        d="M10.2921 10.0299V22.3164L8.99989 22.1787V8.7362C8.99989 6.61503 10.7194 4.89549 12.8406 4.89549H34.0302C35.718 4.89549 37.2081 5.99737 37.7025 7.61114L39.3415 12.961L38.9816 23.1964L36.4467 8.83006C35.9285 7.25423 34.4571 6.1892 32.7982 6.1892H14.1329C12.0117 6.1892 10.2921 7.90875 10.2921 10.0299Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.48154 8.73616C8.48154 6.32864 10.4332 4.37695 12.8408 4.37695H34.0304C35.9461 4.37695 37.6372 5.62759 38.1984 7.45922L40.4937 22.8124L44.7185 24.3231L34.7326 27.8053C34.2844 26.4422 32.7109 25.8849 31.276 25.8849L28.9716 25.1168C27.1368 25.1168 15.9132 20.81 15.9132 22.6448L10.8108 22.8931L8.48154 22.6448V8.73616ZM12.8408 5.41394C11.0059 5.41394 9.51854 6.90135 9.51854 8.73616V21.7125L9.7738 21.7397V10.0299C9.7738 7.62235 11.7255 5.67067 14.133 5.67067H32.7984C34.6812 5.67067 36.3512 6.87948 36.9394 8.66804L38.1807 12.4424H38.6405L37.2069 7.76298C36.7792 6.36707 35.4903 5.41394 34.0304 5.41394H12.8408Z"
        fill="white"
      />
      <path
        d="M123.915 16.9432V22.9468L125.207 23.0333V15.6495C125.207 13.5283 123.487 11.8088 121.366 11.8088H38.9816C38.4055 11.8088 38.1168 12.5052 38.524 12.9128C38.6453 13.0342 38.8099 13.1025 38.9816 13.1025H120.074C122.195 13.1025 123.915 14.822 123.915 16.9432Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M125.591 15.6495C125.591 13.3162 123.699 11.4247 121.366 11.4247H38.9816C38.0634 11.4247 37.6033 12.5346 38.2522 13.1842C38.4456 13.3778 38.6402 22.8124 38.9138 22.8124L103.481 27.4212C105.39 27.4212 118.844 22.0555 118.844 23.9646L123.531 23.306L125.591 23.444V15.6495ZM121.366 12.1928C123.275 12.1928 124.823 13.7404 124.823 15.6495V22.6227L124.299 22.5876V16.9432C124.299 14.6099 122.407 12.7184 120.074 12.7184H38.9816C38.9119 12.7184 38.845 12.6907 38.7957 12.6413C38.6303 12.4757 38.7476 12.1928 38.9816 12.1928H121.366Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.15918 24.3231C5.15918 22.9267 6.57063 21.7946 8.31174 21.7946H124.909C126.65 21.7946 128.062 22.9267 128.062 24.3231L124.221 91.4714C124.221 92.8679 122.81 94 121.069 94H12.1525C10.4113 94 8.99989 92.8679 8.99989 91.4714L5.15918 24.3231Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M58.1155 44.3203L66.6103 52.8151L75.1049 44.3205L81.205 50.4173L72.71 58.9149L81.2049 67.4098L75.1051 73.5097L66.6103 65.0149L58.1155 73.5097L52.0156 67.4098L60.5104 58.915L52.0156 50.4202L58.1155 44.3203ZM75.1053 47.2202L66.6103 55.7152L58.1155 47.2204L54.9157 50.4202L63.4105 58.915L54.9157 67.4098L58.1155 70.6096L66.6103 62.1148L75.1051 70.6096L78.3049 67.4098L69.8102 58.9151L78.3048 50.4179L75.1053 47.2202Z"
        fill="#73DC78"
      />
      <path
        d="M10.2921 10.0299V22.3164L8.99989 22.1787V8.7362C8.99989 6.61503 10.7194 4.89549 12.8406 4.89549H34.0302C35.718 4.89549 37.2081 5.99737 37.7025 7.61114L39.3415 12.961H37.8052L36.4467 8.83006C35.9285 7.25423 34.4571 6.1892 32.7982 6.1892H14.1329C12.0117 6.1892 10.2921 7.90875 10.2921 10.0299Z"
        fill="#00004B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.48154 8.73616C8.48154 6.32864 10.4332 4.37695 12.8408 4.37695H34.0304C35.9461 4.37695 37.6372 5.62759 38.1984 7.45922L40.0428 13.4794H37.4301L35.9543 8.992C35.506 7.62891 34.2333 6.70766 32.7984 6.70766H14.133C12.2982 6.70766 10.8108 8.19507 10.8108 10.0299V22.8931L8.48154 22.6448V8.73616ZM12.8408 5.41394C11.0059 5.41394 9.51854 6.90135 9.51854 8.73616V21.7125L9.7738 21.7397V10.0299C9.7738 7.62235 11.7255 5.67067 14.133 5.67067H32.7984C34.6812 5.67067 36.3512 6.87948 36.9394 8.66804L38.1807 12.4424H38.6405L37.2069 7.76298C36.7792 6.36707 35.4903 5.41394 34.0304 5.41394H12.8408Z"
        fill="#00004B"
      />
      <path
        d="M123.915 16.9432V22.9468L125.207 23.0333V15.6495C125.207 13.5283 123.487 11.8088 121.366 11.8088H38.9816C38.4055 11.8088 38.1168 12.5052 38.524 12.9128C38.6453 13.0342 38.8099 13.1025 38.9816 13.1025H120.074C122.195 13.1025 123.915 14.822 123.915 16.9432Z"
        fill="#00004B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M125.591 15.6495C125.591 13.3162 123.699 11.4247 121.366 11.4247H38.9816C38.0634 11.4247 37.6033 12.5346 38.2522 13.1842C38.4456 13.3778 38.708 13.4865 38.9816 13.4865H120.074C121.983 13.4865 123.531 15.0341 123.531 16.9432V23.306L125.591 23.444V15.6495ZM121.366 12.1928C123.275 12.1928 124.823 13.7404 124.823 15.6495V22.6227L124.299 22.5876V16.9432C124.299 14.6099 122.407 12.7184 120.074 12.7184H38.9816C38.9119 12.7184 38.845 12.6907 38.7957 12.6413C38.6303 12.4757 38.7476 12.1928 38.9816 12.1928H121.366Z"
        fill="#00004B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.31174 23.5784C7.79893 23.5784 7.38321 23.9118 7.38321 24.3231L11.2239 91.4714C11.2239 91.8828 11.6396 92.2162 12.1525 92.2162H121.069C121.581 92.2162 121.997 91.8828 121.997 91.4714L125.838 24.3231C125.838 23.9118 125.422 23.5784 124.909 23.5784H8.31174ZM5.15918 24.3231C5.15918 22.9267 6.57063 21.7946 8.31174 21.7946H124.909C126.65 21.7946 128.062 22.9267 128.062 24.3231L124.221 91.4714C124.221 92.8679 122.81 94 121.069 94H12.1525C10.4113 94 8.99989 92.8679 8.99989 91.4714L5.15918 24.3231Z"
        fill="#00004B"
      />
    </svg>
  );
}
