import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { iff } from '../../utils/iff';
import { translationWrapper } from '../../utils/commonMethods';
import { FontIcon } from './iconComponent';

export const FooterNotification = (props) => {
  const [isChrome, setIsChrome] = useState('');
  const { t } = translationWrapper(useTranslation(), false);

  useEffect(() => {
    if (localStorage.getItem('isChrome') === 'true') {
      setIsChrome(true);
    } else {
      setIsChrome(sessionStorage.getItem('isChrome'));
    }
  }, [isChrome]);

  const handleOkButton = () => {
    localStorage.setItem('isChrome', true);
    setIsChrome(true);
  };

  const handleCloseClick = () => {
    sessionStorage.setItem('isChrome', true);
    setIsChrome(true);
  };

  return (
    <>
      {iff(
        props.isChrome === false && (isChrome === null || isChrome === ''),
        <div
          className="responsive-msg footer-brower-msg"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <p>{t('common.warning.try_chrome')}</p>
          <button style={{ minWidth: 'auto' }} onClick={handleOkButton}>
            {t(`common.button.dont_show_message`)}
          </button>
          <button
            onClick={handleCloseClick}
            className="footer-close-btn"
            style={{ minWidth: '25px', background: 'transparent' }}
          >
            <FontIcon name="close-glyph" size={12} color="#fff" />
          </button>
        </div>
      )}
    </>
  );
};
