import {
  GET_VOUCHER_REDEEM_INFO_LOADING,
  GET_VOUCHER_REDEEM_INFO_SUCCESS,
  GET_VOUCHER_REDEEM_INFO_ERROR,
  REDEEM_VOUCHER_LOADING,
  REDEEM_VOUCHER_SUCCESS,
  REDEEM_VOUCHER_ERROR
} from '../actions/types';

const initialState = {
  loading: false,
  hasLoaded: false,
  error: null,
  success: false,
  data: {},
  redeemInfo: {}
};

export const vouchers = (state = initialState, action) => {
  switch (action.type) {
    case GET_VOUCHER_REDEEM_INFO_LOADING:
    case REDEEM_VOUCHER_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        hasLoaded: false
      };
    case GET_VOUCHER_REDEEM_INFO_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
        redeemInfo: action.payload,
        hasLoaded: true
      };
    case GET_VOUCHER_REDEEM_INFO_ERROR:
    case REDEEM_VOUCHER_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.error,
        hasLoaded: true
      };
    case REDEEM_VOUCHER_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
        data: action.payload,
        hasLoaded: true
      };
    default:
      return state;
  }
};
