import { Children, isValidElement, cloneElement, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { isUsingRBAC, verifyPermision } from 'redux/actions/user';
import { PERMISSIONS_METADATA } from 'rbac/rbacActions';
import { connect } from 'react-redux';
import { useRbacMethods } from './useRbacMethods.hook';
import { useSelector } from 'react-redux';
import { getRolloutLoading } from 'redux/selectors/rolloutSelector';
import { isUserLoaded } from 'redux/selectors/userSelector';

const RbacAccessHoCComponent = (props: any) => {
  const [rbacPermissions, setRbacPermissions] = useState<any>({});
  const location: any = useLocation();
  const { checkIsValidRbacPayloadData } = useRbacMethods();
  const isFeatureFlagLoaded = !useSelector(getRolloutLoading);
  const isUserLoggedIn = useSelector(isUserLoaded);

  useEffect(() => {
    const fetchRbacPermission = async (rbacPayload: any) => {
      const isValidRbacPayload = checkIsValidRbacPayloadData(rbacPayload);
      if (isValidRbacPayload) {
        const { allowed_operations = [] } = await verifyPermision(rbacPayload);
        const rbacPermissionsObj: any = {
          isUsingRBAC: isUsingRBAC()
        };
        allowed_operations.forEach((permission: any) => {
          rbacPermissionsObj[permission.action] = true;
        });
        setRbacPermissions(rbacPermissionsObj);
      }
    };

    if (isUserLoggedIn && isFeatureFlagLoaded) {
      for (const pathName in PERMISSIONS_METADATA) {
        const matchedPath = matchPath({ path: pathName }, window.location.pathname);
        if (matchedPath) {
          fetchRbacPermission(PERMISSIONS_METADATA[pathName](matchedPath.params));
          break;
        }
      }
    }
  }, [
    isUserLoggedIn,
    location,
    isFeatureFlagLoaded,
    checkIsValidRbacPayloadData,
    props.currentWorkspaceId
  ]);

  return Children.map(props.children, (child: any) => {
    if (isValidElement(child)) {
      return cloneElement(child, { ...props, rbacPermissions });
    }
    return child;
  });
};
const mapStateToProps = (state: any) => {
  return {
    currentWorkspaceId: state.orgs.orgById?.workspace_id
  };
};
const mapDispatchToProps = () => ({});

export const RbacAccessHoC = connect(mapStateToProps, mapDispatchToProps)(RbacAccessHoCComponent);
