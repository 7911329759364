import { SortIconsV2 } from 'components/cropcycle-rebranding/landing-property-view/sortIcons';
import { useRef, useLayoutEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import * as Styled from '../archived-list.style';
import { SortDirectionEnum } from '../use-archived-fields.hook';
import { ArchivedField } from './archived-field.component';
import { useTranslation } from 'react-i18next';
import { FieldModel } from '../../archiving.model';
import { EmptyStateIcon } from '../empty-state.icon';

interface ArchivedFieldsListProps {
  fields: FieldModel[];
  selectedFieldsIds: string[];
  isAllSelected: boolean;
  sortDirection: SortDirectionEnum;
  onToggleSortDirection: () => void;
  onSelectAllFields: () => void;
  onSelectField: (id: string) => void;
}

export function ArchivedFieldsList({
  fields,
  sortDirection,
  selectedFieldsIds,
  isAllSelected,
  onToggleSortDirection,
  onSelectAllFields,
  onSelectField
}: ArchivedFieldsListProps) {
  const { t } = useTranslation();
  const selectAllRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (selectAllRef.current) {
      selectAllRef.current.indeterminate = selectedFieldsIds.length > 0 && !isAllSelected;
    }
  }, [selectedFieldsIds, isAllSelected]);

  return (
    <>
      <Styled.CheckAllHeader
        onClick={onToggleSortDirection}
        role="button"
        aria-label={t('archiving.archived_list.toggle_sort_direction_label') as string}
      >
        <Styled.StyledCheckbox
          type="checkbox"
          ref={selectAllRef}
          checked={isAllSelected}
          onClick={(ev) => {
            ev.stopPropagation();
          }}
          onChange={onSelectAllFields}
        />
        <span>{t('archiving.archived_list.check_all_header')}</span>
        <SortIconsV2 orderBy={sortDirection} />
      </Styled.CheckAllHeader>

      {fields.length === 0 ? (
        <Styled.EmptyStateSearchContainer>
          <EmptyStateIcon />
          <div>
            <p>
              <b>{t('archiving.archived_list.empty_state_search_title')}</b>
            </p>
            <p>{t('archiving.archived_list.empty_state_search_description')}</p>
          </div>
        </Styled.EmptyStateSearchContainer>
      ) : (
        <div style={{ flex: '1 1 auto' }}>
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <FixedSizeList height={height} width={width} itemCount={fields.length} itemSize={52}>
                {({ index, style }) => {
                  const item = fields[index];
                  return (
                    <div style={style}>
                      <ArchivedField
                        id={item.id}
                        name={item.name}
                        area={item.declared_area || item.calculated_area}
                        checked={selectedFieldsIds.includes(item.id)}
                        onSelect={() => {
                          onSelectField(item.id);
                        }}
                      />
                    </div>
                  );
                }}
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      )}
    </>
  );
}
