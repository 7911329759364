import { createSelector } from 'reselect';

const getRolloutState = (state) => state.rollout || {};

export const getRolloutFlags = (state) => state.rollout.flags || {};

export const getRolloutLoading = createSelector(getRolloutState, (state) => state.loading);

export const getSecondsBeforeQueueAsyncService = createSelector(
  getRolloutFlags,
  (flags) => flags['FarmSettings.secondsBeforeQueueAsyncService']
);

const ID_TO_ENABLE_ASYNC_SERVICE_ON_ALL_ACCOUNTS = 'ALL';
export const isAccountUsingAsyncService = createSelector(
  [getRolloutFlags, (state) => state.user?.userProfile?.id ?? 'invalid-account'],
  (flags, accountId) => {
    const accountsUsingAsyncService = flags['FarmSettings.accountsUsingAsyncService'];

    if (typeof accountsUsingAsyncService === 'string') {
      const ids = accountsUsingAsyncService.split(',').map((id) => id?.trim());
      if (ids.includes(ID_TO_ENABLE_ASYNC_SERVICE_ON_ALL_ACCOUNTS) || ids.includes(accountId)) {
        return true;
      }
    }

    return false;
  }
);
