import { normalize } from 'normalizr';
import { GET_ORG_RBAC_CRUD_PAYLOAD } from 'rbac/rbacActions';
import * as schema from './schema';
import { request } from '../../utils/axios';
import { getHeaders, getUrl } from '../../utils/commonMethods';
import {
  SET_ORGANIZATIONS_LIST_LOADING,
  SET_ORGANIZATIONS_LIST_SUCCESS,
  SET_ORGANIZATIONS_LIST_ERROR,
  CREATE_ORG_LIST_SUCCESS,
  CREATE_ORG_LIST_ERROR,
  CREATE_ORG_LIST_LOADING,
  EDIT_ORG_LIST_SUCCESS,
  EDIT_ORG_LIST_ERROR,
  EDIT_ORG_LIST_LOADING,
  HIDE_SHOW_ALL_PRODUCTS_LOADING,
  HIDE_SHOW_ALL_PRODUCTS_SUCCESS,
  HIDE_SHOW_ALL_PRODUCTS_ERROR,
  UPLOAD_ORG_LIST_ITEM_AVATAR_LOADING,
  UPLOAD_ORG_LIST_ITEM_AVATAR_SUCCESS,
  UPLOAD_ORG_LIST_ITEM_AVATAR_ERROR,
  SET_ALL_ORGANIZATIONS_LIST_LOADING,
  SET_ALL_ORGANIZATIONS_LIST_SUCCESS,
  SET_ALL_ORGANIZATIONS_LIST_ERROR,
  RESET_ORG_LIST,
  SET_LOADING_ORGS_AND_PROPERTIES_DATA
} from './types';
import { getCurrentWorkspace } from '../selectors/userSelector';
import { isUsingRBAC, verifyPermision } from './user';
import { fetchWorkspacesApps } from './connectedDecks';
import { captureException } from '@sentry/react';

const ORGS_BY_WORKSPACE_CHUNK_SIZE = 20;

function setOrgListLoading() {
  return {
    type: SET_ORGANIZATIONS_LIST_LOADING
  };
}

export function setOrgListSuccess(orgsList) {
  return {
    type: SET_ORGANIZATIONS_LIST_SUCCESS,
    payload: normalize(orgsList, schema.orgsListSchema)
  };
}

function setOrgListError(error) {
  return {
    type: SET_ORGANIZATIONS_LIST_ERROR,
    payload: error
  };
}
const validateOrgList = async (orgs) => {
  try {
    let permissionPayload = [];
    orgs.forEach((o) => {
      permissionPayload = permissionPayload.concat(GET_ORG_RBAC_CRUD_PAYLOAD(o.workspace_id, o.id));
    });
    const { allowed_operations } = await verifyPermision(permissionPayload);

    const permittedOrgIds = allowed_operations.map((o) => o.resource.split(':')[2].split('/')[2]);
    const isRbacEnabled = isUsingRBAC();
    return orgs.filter((org) => {
      if (permittedOrgIds.includes(org.id)) {
        org.rbacPermissions = {
          writeOrg: true,
          deleteOrg: true
        };
        org.rbacPermissions.isUsingRBAC = isRbacEnabled;
        return true;
      }
      return false;
    });
  } catch (e) {
    return [];
  }
};

let fetchOrgsListByWorkspacesCache = {};
/**
 *
 * @param {*} queryParams
 * @param {*} workspaceIds
 * @returns
 */
const fetchOrgsListByWorkspaces = async (queryParams, workspaceIds = []) => {
  const cacheKey = `${JSON.stringify(queryParams)}-${workspaceIds.join('-')}`;
  if (fetchOrgsListByWorkspacesCache[cacheKey] && fetchOrgsListByWorkspacesCache[cacheKey].data) {
    return fetchOrgsListByWorkspacesCache[cacheKey].data.content;
  }
  const params = {
    ...queryParams,
    attributes: 'workspaces',
    include_extended: true,
    workspaces: workspaceIds.toString() || undefined
  };
  if (!params.licensing_accounts) {
    delete params.licensing_accounts;
  }
  fetchOrgsListByWorkspacesCache[cacheKey] = await request.get(getUrl(`/v2/orgs`, params), {
    headers: getHeaders()
  });
  return fetchOrgsListByWorkspacesCache[cacheKey].data.content;
};

/**
 * if there are more than 20 workspaces, use this method
 * @param {*} dispatch
 * @param {*} workspaceIds
 * @returns
 */
const fetchOrgsListByWorkspacesInBulk = async (queryParams, workspaceIds = []) => {
  const allOrgsByWorkspacesResult = [];
  for (
    let workspaceIndex = 0;
    workspaceIndex < workspaceIds.length;
    workspaceIndex += ORGS_BY_WORKSPACE_CHUNK_SIZE
  ) {
    const chunk = workspaceIds.slice(workspaceIndex, workspaceIndex + ORGS_BY_WORKSPACE_CHUNK_SIZE);
    const allOrgsResponse = await fetchOrgsListByWorkspaces(queryParams, chunk);
    allOrgsByWorkspacesResult.push(allOrgsResponse);
  }
  return allOrgsByWorkspacesResult.flat();
};

/**
 * used to display orgs on hall of orgs page
 * @param {*} queryParams
 * @returns
 */
export const fetchOrgsList = (queryParams = null) => {
  return async (dispatch, getState) => {
    const {
      orgsList: {
        data: { entities },
        loading = false,
        isFetchOrgListSuccess = false
      }
    } = getState();

    /**
     * Get workspaces that have contract with Base to fetch their organizations.
     *  */
    const workspacesApps = await dispatch(fetchWorkspacesApps());
    let workspacesIds = [];
    if (Array.isArray(workspacesApps)) {
      workspacesIds = workspacesApps?.map((workspaceApp) => workspaceApp.workspace_id);
    }

    const currentWorkspace = getCurrentWorkspace(getState());
    if (!queryParams) {
      if (
        (entities !== undefined && entities !== null && Object.keys(entities).length !== 0) ||
        loading === true ||
        isFetchOrgListSuccess === true
      ) {
        return;
      }
    }
    dispatch(setOrgListLoading());

    if (
      (Array.isArray(workspacesIds) && workspacesIds.length === 0) ||
      !Array.isArray(workspacesIds)
    ) {
      workspacesIds = [currentWorkspace];
    }

    try {
      let res;
      if (workspacesIds && workspacesIds.length > ORGS_BY_WORKSPACE_CHUNK_SIZE) {
        res = await fetchOrgsListByWorkspacesInBulk(queryParams, workspacesIds);
      } else {
        res = await fetchOrgsListByWorkspaces(queryParams, workspacesIds);
      }
      const isDeepAction = window.location.href.includes('deep-actions');
      if (!isDeepAction && res.length) {
        res = await validateOrgList(res);
      }
      dispatch(setOrgListSuccess(res));
      return res;
    } catch (error) {
      captureException(error);
      dispatch(setOrgListError(error));
    }
    return null;
  };
};

function createOrgListLoading() {
  return {
    type: CREATE_ORG_LIST_LOADING
  };
}

function createOrgListSuccess(orgs) {
  return {
    type: CREATE_ORG_LIST_SUCCESS,
    payload: normalize(orgs, schema.orgs)
  };
}

function createOrgListError(error) {
  return {
    type: CREATE_ORG_LIST_ERROR,
    error
  };
}

export const createOrgList = (org) => {
  return (dispatch) => {
    dispatch(createOrgListLoading());
    return request({
      method: 'post',
      url: `/v2/orgs`,
      headers: getHeaders(),
      data: org
    })
      .then((res) => {
        if (res.error) {
          return res.error;
        }
        dispatch(createOrgListSuccess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(createOrgListError(error));
        return Promise.reject(error);
      });
  };
};

function editOrgListLoading() {
  return {
    type: EDIT_ORG_LIST_LOADING
  };
}

function editOrgListSuccess(orgs) {
  return {
    type: EDIT_ORG_LIST_SUCCESS,
    payload: normalize(orgs, schema.orgs)
  };
}

function editOrgListError(error) {
  return {
    type: EDIT_ORG_LIST_ERROR,
    error
  };
}

export const editOrgList = (org, includeExtended) => {
  return (dispatch) => {
    dispatch(editOrgListLoading());
    return request({
      method: 'put',
      url: `/v2/orgs/${org.id}${includeExtended ? '?include_extended=true' : ''}`,
      headers: getHeaders(),
      data: org
    })
      .then((res) => {
        if (res.error) {
          return res.error;
        }
        dispatch(editOrgListSuccess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(editOrgListError(error));
        return Promise.reject(error);
      });
  };
};

function hideShowAllProductsLoading() {
  return {
    type: HIDE_SHOW_ALL_PRODUCTS_LOADING
  };
}

function hideShowAllProductsSuccess(orgs) {
  return {
    type: HIDE_SHOW_ALL_PRODUCTS_SUCCESS,
    payload: normalize(orgs, schema.orgs)
  };
}

function hideShowAllProductsError(error) {
  return {
    type: HIDE_SHOW_ALL_PRODUCTS_ERROR,
    error
  };
}

export const hideShowAllProducts = (payload) => {
  const data = {
    org_id: payload.props.currentOrg.id,
    source: payload.props.currentOrg.source,
    country: payload.country,
    operation: payload.operation
  };

  return (dispatch) => {
    dispatch(hideShowAllProductsLoading());
    return request({
      method: 'post',
      url: 'v2/catalog/products/visibility',
      headers: getHeaders(),
      data: data
    })
      .then((res) => {
        if (res.error) {
          return res.error;
        }
        dispatch(hideShowAllProductsSuccess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(hideShowAllProductsError(error));
        return Promise.reject(error);
      });
  };
};

const uploadOrgListItemAvatarLoading = () => {
  return {
    type: UPLOAD_ORG_LIST_ITEM_AVATAR_LOADING
  };
};

const uploadOrgListItemAvatarSuccess = (payload) => {
  return {
    type: UPLOAD_ORG_LIST_ITEM_AVATAR_SUCCESS,
    payload
  };
};

const uploadOrgListItemAvatarError = (error) => {
  return {
    type: UPLOAD_ORG_LIST_ITEM_AVATAR_ERROR,
    error
  };
};

export const uploadOrgListItemAvatar = (org, file) => {
  return (dispatch) => {
    dispatch(uploadOrgListItemAvatarLoading());
    const data = new FormData();
    data.append('file', file);
    return request({
      method: 'post',
      url: `/v2/orgs/${org.id}/picture`,
      headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' },
      data
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }

        dispatch(uploadOrgListItemAvatarSuccess({ org, res }));
        return res;
      })
      .catch((error) => {
        dispatch(uploadOrgListItemAvatarError(error));
      });
  };
};

function setAllOrgsLoading() {
  return {
    type: SET_ALL_ORGANIZATIONS_LIST_LOADING
  };
}

function setLoadingOrgsAndPropertiesData() {
  return {
    type: SET_LOADING_ORGS_AND_PROPERTIES_DATA
  };
}

function setAllOrgsSuccess(orgsList) {
  return {
    type: SET_ALL_ORGANIZATIONS_LIST_SUCCESS,
    payload: normalize(orgsList, schema.orgsListSchema)
  };
}

function setAllOrgsError(error) {
  return {
    type: SET_ALL_ORGANIZATIONS_LIST_ERROR,
    payload: error
  };
}

let orgsByWorkspaceIdCache = {};
export const fetchOrgsByWorkspaceId =
  (workspaceId, size = 20, page = 0, forceList = false, searchValue = '') =>
  async (dispatch) => {
    if (
      orgsByWorkspaceIdCache[`${workspaceId}-${size}-${page}-${forceList}-${searchValue}`]?.data
    ) {
      dispatch(
        setAllOrgsSuccess(
          orgsByWorkspaceIdCache[`${workspaceId}-${size}-${page}-${forceList}-${searchValue}`].data
            .content
        )
      );
      return orgsByWorkspaceIdCache[`${workspaceId}-${size}-${page}-${forceList}-${searchValue}`];
    }
    dispatch(setAllOrgsLoading());
    dispatch(setLoadingOrgsAndPropertiesData());

    try {
      orgsByWorkspaceIdCache[`${workspaceId}-${size}-${page}-${forceList}-${searchValue}`] =
        await request.get(
          getUrl(
            `v2/orgs?attributes=licensing_accounts&force_list_unexplicit_orgs=${forceList}&licensing_accounts=${workspaceId}&size=${size}&page=${page}&name=${searchValue}`
          ),
          {
            headers: getHeaders()
          }
        );

      dispatch(
        setAllOrgsSuccess(
          orgsByWorkspaceIdCache[`${workspaceId}-${size}-${page}-${forceList}-${searchValue}`].data
            .content
        )
      );

      return orgsByWorkspaceIdCache[`${workspaceId}-${size}-${page}-${forceList}-${searchValue}`];
    } catch (error) {
      orgsByWorkspaceIdCache[`${workspaceId}-${size}-${page}-${forceList}-${searchValue}`] =
        undefined;
      dispatch(setAllOrgsError(error));
    }
    return null;
  };

// Get orgs API has a limit of size 20 per request when force_list_unexplicit_orgs is false (most scenarios)
export const fetchAllOrgsInChunkByWorkspaceId =
  (workspaceId, forceList = false) =>
  async () => {
    const allOrgsByWorkspacesResponse = [];
    let isLast = false;
    let page = 0;
    while (isLast === false) {
      try {
        const res = await request.get(
          getUrl(
            `v2/orgs?attributes=licensing_accounts&force_list_unexplicit_orgs=${forceList}&licensing_accounts=${workspaceId}&size=${20}&page=${page}`
          ),
          {
            headers: getHeaders()
          }
        );
        isLast = res.data.last;
        page++;
        allOrgsByWorkspacesResponse.push(res.data.content);
      } catch (error) {
        isLast = true;
      }
    }
    return allOrgsByWorkspacesResponse.flat();
  };

export const resetOrgList = () => {
  return {
    type: RESET_ORG_LIST
  };
};
