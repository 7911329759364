import { store } from 'redux/reducer/store';

import { request } from '../../utils/axios';
import { getHeaders } from '../../utils/commonMethods';
import {
  GET_TERMS_CONDITIONS_LOADING,
  GET_TERMS_CONDITIONS_SUCCESS,
  GET_TERMS_CONDITIONS_ERROR
} from './types';

function fetchTermsAndConditionsLoading() {
  return {
    type: GET_TERMS_CONDITIONS_LOADING
  };
}
function fetchTermsAndConditionsSuccess(value) {
  return {
    type: GET_TERMS_CONDITIONS_SUCCESS,
    payload: value
  };
}
function fetchTermsAndConditionsError(error) {
  return {
    type: GET_TERMS_CONDITIONS_ERROR,
    error
  };
}

const getLocationQueryParam = () => {
  const reduxState = store.getState();
  const currentUserLocation = reduxState.user.userProfile.country_code;
  if (currentUserLocation) {
    return `?location=${currentUserLocation}`;
  }
  return '';
};

export const fetchTermsAndConditions = (country = undefined) => {
  return async (dispatch) => {
    dispatch(fetchTermsAndConditionsLoading());
    const userLocation = country || getLocationQueryParam();
    try {
      const requestURL = '/v2/legal-docs';
      await request
        .get(`${process.env.REACT_APP_API_URL}${requestURL}/unsigned${userLocation}`, {
          headers: getHeaders(),
          useCache: true
        })
        .then((response) => {
          if (response.status === 200) {
            const unsignedLegalDocsIds = response.data.legal_docs.map((legalDoc) => legalDoc.id);
            dispatch(fetchTermsAndConditionsSuccess(unsignedLegalDocsIds));
            return unsignedLegalDocsIds;
          }
        });
    } catch (error) {
      dispatch(fetchTermsAndConditionsError(error));
    }
    return null;
  };
};
