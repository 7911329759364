import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import {
  SET_ORGANIZATIONS_LIST_LOADING,
  SET_ORGANIZATIONS_LIST_SUCCESS,
  SET_ORGANIZATIONS_LIST_ERROR,
  CREATE_ORG_LIST_SUCCESS,
  CREATE_ORG_LIST_LOADING,
  CREATE_ORG_LIST_ERROR,
  EDIT_ORG_LIST_SUCCESS,
  EDIT_ORG_LIST_ERROR,
  EDIT_ORG_LIST_LOADING,
  HIDE_SHOW_ALL_PRODUCTS_SUCCESS,
  HIDE_SHOW_ALL_PRODUCTS_LOADING,
  HIDE_SHOW_ALL_PRODUCTS_ERROR,
  DELETE_ORG_LIST_ITEM_RESET,
  DELETE_ORG_LIST_ITEM_LOADING,
  DELETE_ORG_LIST_ITEM_SUCCESS,
  DELETE_ORG_LIST_ITEM_ERROR,
  UPLOAD_ORG_LIST_ITEM_AVATAR_LOADING,
  UPLOAD_ORG_LIST_ITEM_AVATAR_SUCCESS,
  UPLOAD_ORG_LIST_ITEM_AVATAR_ERROR,
  REMOVE_ORG_LIST_ITEM_AVATAR_LOADING,
  REMOVE_ORG_LIST_ITEM_AVATAR_SUCCESS,
  REMOVE_ORG_LIST_ITEM_AVATAR_ERROR,
  SET_ALL_ORGANIZATIONS_LIST_LOADING,
  SET_ALL_ORGANIZATIONS_LIST_SUCCESS,
  SET_ALL_ORGANIZATIONS_LIST_ERROR,
  RESET_ORG_LIST
} from '../actions/types';

const initialState = {
  data: {
    entities: {},
    result: []
  },
  allOrgs: {
    entities: {},
    result: []
  },
  error: null,
  loading: false,
  createOrgLoading: false,
  createOrgSuccess: false,
  createOrgError: null,
  editOrgLoading: false,
  editOrgSuccess: false,
  editOrgError: null,
  hideShowAllProductsLoading: false,
  hideShowAllProductsSuccess: false,
  hideShowAllProductsError: null,
  deleteOrgLoading: false,
  deleteOrgSuccess: false,
  deleteOrgError: null,
  uploadOrgAvatarLoading: false,
  uploadOrgAvatarSuccess: false,
  uploadOrgAvatarError: null,
  removeOrgAvatarLoading: false,
  removeOrgAvatarSuccess: false,
  removeOrgAvatarError: null,
  isFetchOrgListSuccess: false
};

export const orgsList = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATIONS_LIST_LOADING:
      return {
        ...initialState,
        allOrgs: { ...state.allOrgs },
        loading: true,
        isFetchOrgListSuccess: false
      };
    case SET_ORGANIZATIONS_LIST_SUCCESS:
      const newData = {
        entities: { orgs: { ...state.data.entities.orgs, ...action.payload.entities.orgs } },
        result: [...state.data.result, ...action.payload.result]
      };
      return {
        ...initialState,
        data: newData,
        isFetchOrgListSuccess: true,
        allOrgs: newData
      };
    case SET_ORGANIZATIONS_LIST_ERROR:
      return {
        ...initialState,
        error: action.payload,
        isFetchOrgListSuccess: false,
        allOrgs: { ...state.allOrgs }
      };
    case CREATE_ORG_LIST_LOADING:
      return {
        ...state,
        createOrgLoading: true
      };
    case CREATE_ORG_LIST_SUCCESS:
      return {
        ...initialState,
        createOrgSuccess: true,
        data: {
          entities: { orgs: { ...state.data.entities.orgs, ...action.payload.entities.orgs } },
          result: [...state.data.result, action.payload.result]
        },
        allOrgs: {
          entities: { orgs: { ...state.allOrgs.entities.orgs, ...action.payload.entities.orgs } },
          result: [...state.allOrgs.result, action.payload.result]
        }
      };
    case CREATE_ORG_LIST_ERROR:
      return {
        ...state,
        createOrgLoading: false,
        createOrgError: action.error
      };
    case EDIT_ORG_LIST_SUCCESS:
      return {
        ...state,
        editOrgLoading: false,
        editOrgSuccess: true,
        data: {
          ...state.data,
          entities: { orgs: { ...state.data.entities.orgs, ...action.payload.entities.orgs } }
        },
        allOrgs: {
          ...state.allOrgs,
          entities: { orgs: { ...state.allOrgs.entities.orgs, ...action.payload.entities.orgs } }
        }
      };
    case EDIT_ORG_LIST_ERROR:
      return {
        ...state,
        editOrgError: action.error,
        editOrgLoading: false
      };
    case EDIT_ORG_LIST_LOADING:
      return {
        ...state,
        editOrgLoading: true
      };
    case HIDE_SHOW_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        hideShowAllProductsLoading: false,
        hideShowAllProductsSuccess: true,
        data: {
          ...state.data,
          entities: { orgs: { ...state.data.entities.orgs, ...action.payload.entities.orgs } }
        },
        allOrgs: {
          ...state.allOrgs,
          entities: { orgs: { ...state.allOrgs.entities.orgs, ...action.payload.entities.orgs } }
        }
      };
    case HIDE_SHOW_ALL_PRODUCTS_ERROR:
      return {
        ...state,
        hideShowAllProductsError: action.error,
        hideShowAllProductsLoading: false
      };
    case HIDE_SHOW_ALL_PRODUCTS_LOADING:
      return {
        ...state,
        hideShowAllProductsLoading: true
      };
    case DELETE_ORG_LIST_ITEM_RESET:
      return {
        ...state,
        deleteOrgSuccess: false,
        deleteOrgLoading: false,
        deleteOrgError: null
      };
    case DELETE_ORG_LIST_ITEM_LOADING:
      return {
        ...state,
        deleteOrgLoading: true,
        deleteOrgError: null,
        deleteOrgSuccess: false
      };
    case DELETE_ORG_LIST_ITEM_SUCCESS:
      const updatedOrgs = omit(state.data.entities.orgs, [action.payload.id]);
      const result = state.data.result.filter((id) => id !== action.payload.id);
      const updatedOrgsallOrgs = omit(state.allOrgs.entities.orgs, [action.payload.id]);
      const resultallOrgs = state.allOrgs.result.filter((id) => id !== action.payload.id);

      return {
        ...state,
        deleteOrgLoading: false,
        deleteOrgError: null,
        deleteOrgSuccess: true,
        data: {
          entities: { orgs: updatedOrgs },
          result: result
        },
        allOrgs: {
          entities: { orgs: updatedOrgsallOrgs },
          result: resultallOrgs
        }
      };
    case DELETE_ORG_LIST_ITEM_ERROR:
      return {
        ...state,
        deleteOrgLoading: false,
        deleteOrgSuccess: false,
        deleteOrgError: action.error
      };
    case UPLOAD_ORG_LIST_ITEM_AVATAR_LOADING:
      return {
        ...state,
        uploadOrgAvatarLoading: true,
        uploadOrgAvatarSuccess: false,
        uploadOrgAvatarError: null
      };

    case UPLOAD_ORG_LIST_ITEM_AVATAR_ERROR:
      return {
        ...state,
        uploadOrgAvatarLoading: false,
        uploadOrgAvatarError: action.error
      };

    case UPLOAD_ORG_LIST_ITEM_AVATAR_SUCCESS:
      let updatedAvatar = cloneDeep(state.data.entities.orgs[action.payload.org.id]);
      updatedAvatar.picture_url = action.payload.res.data.avatar_url;
      let updatedAvatarOrgs = {
        ...omit(state.data.entities.orgs, [action.payload.org.id]),
        [action.payload.org.id]: updatedAvatar
      };

      return {
        ...state,
        data: {
          ...state.data,
          entities: { orgs: { ...updatedAvatarOrgs } }
        },
        uploadOrgAvatarLoading: false,
        uploadOrgAvatarSuccess: true
      };

    case REMOVE_ORG_LIST_ITEM_AVATAR_LOADING:
      return {
        ...state,
        removeOrgAvatarLoading: true,
        removeOrgAvatarSuccess: false,
        removeOrgAvatarError: null
      };

    case REMOVE_ORG_LIST_ITEM_AVATAR_ERROR:
      return {
        ...state,
        removeOrgAvatarLoading: false,
        removeOrgAvatarError: action.error
      };

    case REMOVE_ORG_LIST_ITEM_AVATAR_SUCCESS:
      const removedAvatar = cloneDeep(state.data.entities.orgs[action.payload.id]);
      removedAvatar.picture_url = null;
      const removedAvatarOrgs = {
        ...omit(state.data.entities.orgs, [action.payload.id]),
        [action.payload.id]: removedAvatar
      };

      return {
        ...state,
        data: {
          ...state.data,
          entities: { orgs: { ...removedAvatarOrgs } }
        },
        removeOrgAvatarLoading: false,
        removeOrgAvatarSuccess: true
      };
    case SET_ALL_ORGANIZATIONS_LIST_LOADING:
      return {
        ...initialState,
        loading: true,
        data: { ...state.data },
        isFetchOrgListSuccess: state.isFetchOrgListSuccess
      };
    case SET_ALL_ORGANIZATIONS_LIST_SUCCESS:
      const allOrgsData = {
        entities: { ...state.allOrgs.entities, ...action.payload.entities },
        result: [...new Set([...state.allOrgs.result, ...action.payload.result])]
      };
      return {
        ...initialState,
        allOrgs: allOrgsData,
        loading: false,
        data: allOrgsData,
        isFetchOrgListSuccess: state.isFetchOrgListSuccess
      };
    case SET_ALL_ORGANIZATIONS_LIST_ERROR:
      return {
        ...initialState,
        error: action.payload,
        data: { ...state.data },
        isFetchOrgListSuccess: state.isFetchOrgListSuccess
      };
    case RESET_ORG_LIST:
      return {
        ...state,
        createOrgLoading: false,
        createOrgSuccess: false,
        createOrgError: null,
        editOrgLoading: false,
        editOrgSuccess: false,
        editOrgError: null,
        deleteOrgLoading: false,
        deleteOrgSuccess: false,
        deleteOrgError: null
      };
    default:
      return state;
  }
};
