import {
  FETCH_DISTRIBUTION_ACCESS_KEY_SUCCESS,
  FETCH_DISTRIBUTION_ACCESS_KEY_ERROR,
  FETCH_DISTRIBUTION_ACCESS_KEY_LOADING,
  REDEEM_DISTRIBUTION_ACCESS_KEY_ERROR,
  REDEEM_DISTRIBUTION_ACCESS_KEY_LOADING,
  REDEEM_DISTRIBUTION_ACCESS_KEY_SUCCESS
} from '../actions/types';

const initialState = {
  loading: false,
  error: false,
  success: false,
  data: null,
  redeemResponse: null
};

export const accessKey = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DISTRIBUTION_ACCESS_KEY_LOADING:
    case REDEEM_DISTRIBUTION_ACCESS_KEY_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        success: false
      };
    case FETCH_DISTRIBUTION_ACCESS_KEY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        success: true,
        error: false,
        loading: false
      };
    case REDEEM_DISTRIBUTION_ACCESS_KEY_SUCCESS:
      return {
        ...state,
        redeemResponse: action.payload,
        success: true,
        error: false,
        loading: false
      };
    case FETCH_DISTRIBUTION_ACCESS_KEY_ERROR:
    case REDEEM_DISTRIBUTION_ACCESS_KEY_ERROR:
      return {
        ...state,
        error: true,
        success: false,
        loading: false
      };
    default:
      return state;
  }
};
