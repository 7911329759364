import {
  CREATE_BILLING_INTENT_LOADING,
  CREATE_BILLING_INTENT_SUCCESS,
  CREATE_BILLING_INTENT_ERROR
} from '../actions/types';

const initialState = {
  loading: false,
  hasLoaded: false,
  error: null,
  success: false,
  data: {}
};

export const billing = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BILLING_INTENT_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        hasLoaded: false
      };
    case CREATE_BILLING_INTENT_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
        data: action.payload,
        hasLoaded: true
      };
    case CREATE_BILLING_INTENT_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.error,
        hasLoaded: true
      };
    default:
      return state;
  }
};
