export default function ReportProblemIcon() {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.75 15.1406L9 0.890625L17.25 15.1406H0.75ZM14.6473 13.6393L8.99979 3.88184L3.35229 13.6393H14.6473ZM8.25 11.3906V12.8906H9.75V11.3906H8.25ZM8.25 6.89062H9.75V9.89062H8.25V6.89062Z"
        fill="#F0C355"
      />
    </svg>
  );
}
