import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Cropwise from '../../public/cropwise.svg';
import SyngentaDigital from '../../public/syngenta_digital.svg';
import IllustrationLetsstart from '../../public/illustration_letsstart.svg';
import { isUK, translationWrapper } from '../../utils/commonMethods';
import { iff } from '../../utils/iff';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .illustration-position{
      margin-top: 28px;
  }
  .loading-text {
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #00004b;
    margin-top: 12px;
  }
  .spinner {
    .ant-spin-dot {
      top: 72%;
      right: 49%;
      color: #14803C;
      @media screen and (max-width: 1350px) {{
        top: 78%;
      }
    }
  }
`;

export const RedirectionLoader = () => {
  const { t } = translationWrapper(useTranslation(), false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <StyledContainer>
      <div>
        <img src={iff(isUK(), SyngentaDigital, Cropwise)} alt="Cropwise" />
      </div>
      <div className="illustration-position">
        <img src={IllustrationLetsstart} alt="Illustration Letsstart" />
      </div>
      <div className="loading-text">{t('global.loading_text')}</div>
      <Spin className="spinner" indicator={antIcon} />
    </StyledContainer>
  );
};
