import get from 'lodash/get';
import { request } from '../../utils/axios';
import { getHeaders } from '../../utils/commonMethods';
import {
  ADD_MANAGED_USER_LOADING,
  ADD_MANAGED_USER_SUCCESS,
  ADD_MANAGED_USER_ERROR,
  GET_USER_DETAILS_LOADING,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_ERROR,
  INVITE_USER_LOADING,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,
  EDIT_USER_PERMISSION_LOADING,
  EDIT_USER_PERMISSION_SUCCESS,
  EDIT_USER_PERMISSION_ERROR,
  EDIT_USER_DETAILS_LOADING,
  EDIT_USER_DETAILS_SUCCESS,
  EDIT_USER_DETAILS_ERROR,
  REMOVE_USER_LOADING,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_ERROR,
  GET_USER_DETAILS_BY_STATUS_LOADING,
  GET_USER_DETAILS_BY_STATUS_SUCCESS,
  GET_USER_DETAILS_BY_STATUS_ERROR,
  SET_EDIT_MEMBER_INVITATION_DATA,
  SET_CONFIRMATION_MODEL_VISIBILITY,
  SET_ORGANIZATIONS_BY_WORKSPACE_DATA,
  SET_PROPERTIES_BY_ORGANIZATIONS_DATA
} from './types';
import { RBAC_ROLES_UPDATES_CHUNK_SIZE } from './rbac';

const RESPONSE_DATA = 'response.data';

export function setConfirmationModelVisibility(payload) {
  return {
    type: SET_CONFIRMATION_MODEL_VISIBILITY,
    payload: payload
  };
}

function addManagedUserLoading(payload) {
  return {
    type: ADD_MANAGED_USER_LOADING,
    payload: payload
  };
}

function addManagedUserSuccess(managedUser) {
  return {
    type: ADD_MANAGED_USER_SUCCESS,
    payload: managedUser
  };
}
function addManagedUserError(error) {
  return {
    type: ADD_MANAGED_USER_ERROR,
    error
  };
}

function getUserDetailsLoading(payload) {
  return {
    type: GET_USER_DETAILS_LOADING,
    payload: payload
  };
}

function getUserDetailsSuccess(managedUser) {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload: managedUser
  };
}
function getUserDetailsError(error) {
  return {
    type: GET_USER_DETAILS_ERROR,
    error
  };
}

function inviteUserLoading(payload) {
  return {
    type: INVITE_USER_LOADING,
    payload: payload
  };
}

function inviteUserSuccess(managedUser) {
  return {
    type: INVITE_USER_SUCCESS,
    payload: managedUser
  };
}
function inviteUserError(error) {
  return {
    type: INVITE_USER_ERROR,
    error
  };
}

function editUserPermissionLoading(payload) {
  return {
    type: EDIT_USER_PERMISSION_LOADING,
    payload: payload
  };
}

function editUserPermissionSuccess(editedPermission) {
  return {
    type: EDIT_USER_PERMISSION_SUCCESS,
    payload: editedPermission
  };
}
function editUserPermissionError(error) {
  return {
    type: EDIT_USER_PERMISSION_ERROR,
    error
  };
}

function editUserDetailsLoading(payload) {
  return {
    type: EDIT_USER_DETAILS_LOADING,
    payload: payload
  };
}

function editUserDetailsSuccess(editedUserDetails) {
  return {
    type: EDIT_USER_DETAILS_SUCCESS,
    payload: editedUserDetails
  };
}
function editUserDetailsError(error) {
  return {
    type: EDIT_USER_DETAILS_ERROR,
    error
  };
}

function removeUserLoading(payload) {
  return {
    type: REMOVE_USER_LOADING,
    payload: payload
  };
}

function removeUserSuccess(editedPermission) {
  return {
    type: REMOVE_USER_SUCCESS,
    payload: editedPermission
  };
}
function removeUserError(error) {
  return {
    type: REMOVE_USER_ERROR,
    error
  };
}

function getUserDetailsByStatusLoading(payload) {
  return {
    type: GET_USER_DETAILS_BY_STATUS_LOADING,
    payload: payload
  };
}

function getUserDetailsByStatusSuccess(managedUser) {
  return {
    type: GET_USER_DETAILS_BY_STATUS_SUCCESS,
    payload: managedUser
  };
}
function getUserDetailsByStatusError(error) {
  return {
    type: GET_USER_DETAILS_BY_STATUS_ERROR,
    error
  };
}

export const getUserDetailsByStatus = (orgId, status) => {
  return (dispatch) => {
    dispatch(getUserDetailsByStatusLoading(true));
    return request({
      method: 'get',
      url: `/v2/orgs/${orgId}/invite-status?status_list=${status}`,
      headers: getHeaders()
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(getUserDetailsByStatusSuccess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(getUserDetailsByStatusError(get(error, RESPONSE_DATA, {})));
        return error;
      });
  };
};

export const addManagedUser = (managedUserData, orgId) => {
  return (dispatch) => {
    dispatch(addManagedUserLoading(true));
    return request({
      method: 'post',
      url: `/v2/orgs/${orgId}/accounts/`,
      headers: getHeaders(),
      data: managedUserData
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(addManagedUserSuccess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(addManagedUserError(get(error, RESPONSE_DATA, {})));
        return error;
      });
  };
};

const dispatchInvitationResponse = (res, dispatch) => {
  if (res.error) {
    throw res.error;
  }
  dispatch(inviteUserSuccess(res.data));
  return res;
};

export const addInvitedUser = (invitedUserData) => (dispatch) => {
  dispatch(inviteUserLoading(true));
  return request({
    method: 'post',
    url: `/v2/accounts/invitation`,
    headers: getHeaders(),
    data: { ...invitedUserData }
  })
    .then((res) => {
      return dispatchInvitationResponse(res, dispatch);
    })
    .catch((error) => {
      dispatch(inviteUserError(get(error, RESPONSE_DATA, {})));
      return error;
    });
};

const batchInvite = (batchInviteDTO) => async (dispatch) => {
  return request({
    method: 'post',
    url: `/v2/accounts/batch/invitation`,
    headers: getHeaders(),
    data: { ...batchInviteDTO }
  })
    .then((res) => {
      return dispatchInvitationResponse(res, dispatch);
    })
    .catch((error) => {
      dispatch(inviteUserError(get(error, RESPONSE_DATA, {})));
      return error;
    });
};

const batchInviteWithChunks = (batchInviteDTO) => async (dispatch) => {
  const { rbac_roles } = batchInviteDTO;
  const rolesCount = rbac_roles.length;
  const inviteResults = [];
  for (let rolesIndex = 0; rolesIndex < rolesCount; rolesIndex += RBAC_ROLES_UPDATES_CHUNK_SIZE) {
    const newPayload = batchInviteDTO;
    newPayload.rbac_roles = rbac_roles.slice(
      rolesIndex,
      rolesIndex + RBAC_ROLES_UPDATES_CHUNK_SIZE
    );
    const inviteResponse = await dispatch(batchInvite(newPayload));
    inviteResults.push(inviteResponse);
  }
  const resultWithError = inviteResults.find((result) => result.status !== 201);
  return resultWithError ?? inviteResults[0];
};

export const batchInviteUsers = (batchInviteDTO) => (dispatch) => {
  dispatch(inviteUserLoading(true));
  const { rbac_roles } = batchInviteDTO;
  const isRolesCountOutOfLimit = rbac_roles?.length > RBAC_ROLES_UPDATES_CHUNK_SIZE;
  if (rbac_roles && isRolesCountOutOfLimit) {
    return dispatch(batchInviteWithChunks(batchInviteDTO));
  }
  return dispatch(batchInvite(batchInviteDTO));
};

export const getUserDetails = (orgId) => {
  return (dispatch) => {
    dispatch(getUserDetailsLoading(true));
    return request({
      method: 'get',
      url: `/v2/orgs/${orgId}/accounts/`,
      headers: getHeaders()
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(getUserDetailsSuccess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(getUserDetailsError(get(error, RESPONSE_DATA, {})));
        return error;
      });
  };
};

export const getUserDetailByAccountId = (accountId) => (dispatch) => {
  dispatch(getUserDetailsLoading(true));
  return request({
    method: 'get',
    url: `/v2/accounts/${accountId}`,
    headers: getHeaders()
  })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      dispatch(getUserDetailsSuccess(response.data));
      return response;
    })
    .catch((error) => {
      dispatch(getUserDetailsError(get(error, 'response.data', {})));
      return error;
    });
};

export const editUserPermission = (editedPermission, userId) => {
  return (dispatch) => {
    dispatch(editUserPermissionLoading(true));
    return request({
      method: 'post',
      url: `/v2/accounts/${userId}/authorities/updates`,
      headers: getHeaders(),
      data: editedPermission
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(editUserPermissionSuccess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(editUserPermissionError(get(error, RESPONSE_DATA, {})));
        return error;
      });
  };
};

export const editUserDetails = (userDetails, userId) => {
  return (dispatch) => {
    dispatch(editUserDetailsLoading(true));
    return request({
      method: 'put',
      url: `/v2/accounts/${userId}`,
      headers: getHeaders(),
      data: userDetails
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(editUserDetailsSuccess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(editUserDetailsError(get(error, RESPONSE_DATA, {})));
        return error;
      });
  };
};

export const removeUserFromOrg = (user, orgId) => async (dispatch) => {
  const { id: userId, type: userType, authorities } = user;
  dispatch(removeUserLoading(true));

  const orgAuthorities = authorities.filter(
    (authority) => authority.context === 'ORGANIZATION' && authority.id === orgId
  );

  let params = {
    method: 'post',
    url: `/v2/accounts/${userId}/authorities/updates`,
    data: {
      updates: [
        {
          operation: 'remove',
          authorities: orgAuthorities
        }
      ]
    }
  };
  if (userType === 'MANAGED') {
    params = {
      method: 'delete',
      url: `/v2/accounts/${userId}`
    };
  }

  try {
    const response = await request({
      ...params,
      headers: getHeaders()
    });

    if (response.error) {
      throw response.error;
    }
    dispatch(removeUserSuccess({ data: response.data, userId: userId }));
    return response;
  } catch (error) {
    dispatch(removeUserError(get(error, RESPONSE_DATA, {})));
    return error;
  }
};

export const setEditMemberInvitationData = (editMemberInvitationData) => async (dispatch) => {
  dispatch({ type: SET_EDIT_MEMBER_INVITATION_DATA, payload: editMemberInvitationData });
  return editMemberInvitationData;
};

export const setOrganizationsByWorkspaceData =
  (organizationsByWorkspaceData) => async (dispatch) => {
    dispatch({ type: SET_ORGANIZATIONS_BY_WORKSPACE_DATA, payload: organizationsByWorkspaceData });
    return organizationsByWorkspaceData;
  };

export const setPropertiesByOrganizationsData =
  (propertiesByOrganizationsData) => async (dispatch) => {
    dispatch({
      type: SET_PROPERTIES_BY_ORGANIZATIONS_DATA,
      payload: propertiesByOrganizationsData
    });
    return propertiesByOrganizationsData;
  };
