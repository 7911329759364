import {
  GET_CAMPAIGN_LINK_INFO_ERROR,
  GET_CAMPAIGN_LINK_INFO_LOADING,
  GET_CAMPAIGN_LINK_INFO_SUCCESS,
  REDEEM_CAMPAIGN_LINK_ERROR,
  REDEEM_CAMPAIGN_LINK_LOADING,
  REDEEM_CAMPAIGN_LINK_SUCCESS
} from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  success: false,
  redeemData: {},
  campaignData: {}
};

export const campaigns = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_LINK_INFO_LOADING:
      return {
        ...state,
        success: false,
        loading: true
      };
    case GET_CAMPAIGN_LINK_INFO_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
        campaignData: action.payload
      };
    case GET_CAMPAIGN_LINK_INFO_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.error
      };
    case REDEEM_CAMPAIGN_LINK_LOADING:
      return {
        ...state,
        success: false,
        loading: true
      };
    case REDEEM_CAMPAIGN_LINK_SUCCESS:
      return {
        ...state,
        success: true,
        error: null,
        loading: false,
        redeemData: action.payload
      };
    case REDEEM_CAMPAIGN_LINK_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};
