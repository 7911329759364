import { format as formatDFNS } from 'date-fns';

export const DATE_FORMATS = {
  YYYY_DASH_MM_DASH_DD_WITH_TIME: `yyyy-MM-dd'T'HH:mm:ss'Z'`,
  MMM_SPACE_YEAR: `MMM yyyy`,
  YYYY_DASH_MM_DASH_DD: 'yyyy-MM-dd',
  YYYY_DASH_MM_DASH_DD_NUMBER: 'yyyy-MM-DD',
  YYYY_SHLASH_MM_SHLASH_DD: 'yyyy/MM/dd',
  YYYY_DASH_MM_DASH_DDTHH_COLON_mm_COLON_ss: 'YYYY-MM-DDTHH:mm:ss',
  dddd_SPACE_DD_SPACE_MMM: 'dddd DD MMM',
  DD_DASH_MM_DASH_YYYY: 'dd-MM-yyyy',
  DD_DASH_MM_DASH_YY: 'dd-MM-yy',
  MM_DASH_DD_DASH_YYYY: 'MM-dd-yyyy',
  DD_DASH_MMM_DASH_YYYY: 'dd-MMM-yyyy'
};

const newDate = (date) => {
  return date ? new Date(date) : new Date();
};

export const formatDateFNS = (date, formatString = DATE_FORMATS.DD_DASH_MMM_DASH_YYYY) => {
  const date1 = date ? newDate(date) : new Date();
  return formatDFNS(date1, formatString);
};
