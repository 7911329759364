export default function MenuExpand(props: { className: string }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 5C5.44772 5 5 5.44772 5 6C5 6.55228 5.44772 7 6 7H18C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5H6ZM20.2933 13.2933C19.9023 13.6842 19.9023 14.3162 20.2933 14.7073C20.4882 14.9023 20.7443 15.0002 21.0002 15.0002C21.2563 15.0002 21.5123 14.9023 21.7073 14.7073L23.7073 12.7073C24.0982 12.3163 24.0982 11.6842 23.7073 11.2933L21.7073 9.29325C21.3162 8.90225 20.6842 8.90225 20.2933 9.29325C19.9023 9.68425 19.9023 10.3162 20.2933 10.7073L21.5863 12.0002L20.2933 13.2933ZM5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6C5.44772 13 5 12.5523 5 12ZM6 17C5.44772 17 5 17.4477 5 18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17H6Z"
      />
    </svg>
  );
}
