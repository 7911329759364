import { Analytics } from '../analytics/analytics';

const elementDictionary: { [id: string]: Date | undefined } = {};

function addElement(id: string, date: Date | undefined): void {
  elementDictionary[id] = date;
}

function searchElementById(id: string): Date | undefined {
  return elementDictionary[id];
}

export function loadTimeTracker(start: boolean, name: string, isFront = true) {
  let seconds = 0;
  let endDate = null;
  let startDate = null;

  if (start) {
    if (isFront) {
      if (!searchElementById(name + 'FrontStart')) {
        addElement(name + 'FrontStart', new Date());
      }
    } else if (!searchElementById(name + 'BackStart')) {
      addElement(name + 'BackStart', new Date());
    }
  } else {
    if (isFront) {
      endDate = new Date();
      startDate = searchElementById(name + 'FrontStart');
      seconds = startDate ? (endDate.getTime() - startDate.getTime()) / 1000 : 0;
      elementDictionary[name + 'FrontStart'] = undefined;
    } else {
      endDate = new Date();
      startDate = searchElementById(name + 'BackStart');
      seconds = startDate ? (endDate.getTime() - startDate.getTime()) / 1000 : 0;
      elementDictionary[name + 'BackStart'] = undefined;
    }

    const analyticsName = isFront ? 'PageFinishLoading' : 'RequestFinishLoading';

    if (seconds > 0) {
      Analytics.track(analyticsName, {
        pageName: name,
        totalSeconds: seconds
      });
    }
  }
}
