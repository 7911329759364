import { StyledModal } from 'app/common';
import {
  ArchivingModalsEnum,
  useArchivingInternal
} from 'app/fields/archiving/archiving-internal.provider';
import { useTranslation } from 'react-i18next';

export function ConfirmUnarchive() {
  const { t } = useTranslation();

  const {
    loading,
    modals: { [ArchivingModalsEnum.CONFIRM_UNARCHIVE]: open },
    triggerModal,
    unarchiveField
  } = useArchivingInternal();

  const onClose = () => {
    triggerModal(ArchivingModalsEnum.CONFIRM_UNARCHIVE);
  };

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      title={t('archiving.notifications.confirm_unarchive.title')}
      cancel={{
        onClick: onClose,
        text: t('common.button.cancel')
      }}
      ok={{
        onClick: unarchiveField,
        text: t('archiving.notifications.confirm_unarchive.unarchive_button')
      }}
      loading={loading}
    >
      <div style={{ paddingLeft: 40 }}>
        <span>{t('archiving.notifications.confirm_unarchive.description')}</span>
      </div>
    </StyledModal>
  );
}
