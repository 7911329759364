import {
  FETCH_APPS_LOADING,
  FETCH_APPS_SUCCESS,
  FETCH_APPS_ERROR,
  FETCH_WORKSPACES_APPS_ERROR,
  FETCH_WORKSPACES_APPS_LOADING,
  FETCH_WORKSPACES_APPS_SUCCESS,
  RESET_FETCH_APPS,
  FETCH_WORKSPACES_USER_IS_MEMBER_OF_SUCCESS,
  FETCH_APP_INFO_LOADING,
  FETCH_APP_INFO_ERROR,
  FETCH_APP_INFO_SUCCESS,
  FETCH_APP_CLIENTS_LOADING,
  FETCH_APP_CLIENTS_SUCCESS,
  FETCH_APP_CLIENTS_ERROR
} from '../actions/types';

const initialState = {
  loading: false,
  hasLoaded: false,
  error: null,
  data: [],
  workspacesAppsData: [],
  workspacesUserIsMemberOf: [],
  appInfo: undefined,
  appClients: []
};

export const connectedDecks = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WORKSPACES_APPS_LOADING:
    case FETCH_APPS_LOADING:
    case FETCH_APP_INFO_LOADING:
    case FETCH_APP_CLIENTS_LOADING:
      return {
        ...state,
        loading: true,
        hasLoaded: false
      };
    case FETCH_WORKSPACES_APPS_SUCCESS:
      return {
        ...state,
        workspacesAppsData: action.payload,
        loading: false,
        hasLoaded: true
      };
    case FETCH_WORKSPACES_USER_IS_MEMBER_OF_SUCCESS:
      return {
        ...state,
        loading: false,
        hasLoaded: true,
        workspacesUserIsMemberOf: action.payload
      };
    case FETCH_APPS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        hasLoaded: true
      };
    case FETCH_APP_INFO_SUCCESS:
      return {
        ...state,
        appInfo: action.payload,
        loading: false,
        hasLoaded: true
      };
    case FETCH_APP_CLIENTS_SUCCESS:
      return {
        ...state,
        appClients: action.payload,
        loading: false,
        hasLoaded: true
      };
    case FETCH_WORKSPACES_APPS_ERROR:
    case FETCH_APPS_ERROR:
    case FETCH_APP_INFO_ERROR:
    case FETCH_APP_CLIENTS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        hasLoaded: true
      };
    case RESET_FETCH_APPS:
      return {
        ...state,
        data: [],
        error: null,
        loading: false
      };
    default:
      return state;
  }
};
