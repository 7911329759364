import { Button, notification } from 'antd';

export const notifications = {
  success: <T,>(action?: (response?: T) => void, response?: T) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        size="small"
        onClick={() => {
          notification.close(key);
          action?.(response);
        }}
      >
        Reload data
      </Button>
    );
    notification.success({
      key,
      message: 'Task Completed',
      description:
        'Your request has been successfully processed. The task is complete, and the results are ready for you.',
      btn,
      duration: 0
    });
  },
  timeout: (seconds: number) =>
    notification.info({
      message: 'Timeout Exceeded',
      description: `This attempt took longer than expected (more than ${seconds} seconds). Your request is now in the queue and will be processed in the background.`,
      duration: 3
    }),
  concurrency: () =>
    notification.info({
      message: 'One Task at a Time',
      description:
        'Another task is currently in progress. Your request has been added to the queue and will be processed shortly',
      duration: 3
    }),
  error: () =>
    notification.error({
      message: 'Request Failed',
      description:
        'Unfortunately, your request encountered an issue and could not be completed. Subsequent requests have been cancelled to avoid inconsistencies. Please try again later'
    })
};
