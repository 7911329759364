import {
  MEASURE_COORD_MODE_ACTIVE,
  SET_MAPBOX_THEME,
  SET_SHOW_MAPBOX_ROAD_NAMES,
  SET_SHOW_MAPBOX_FIELD_NAMES,
  SET_GET_MEASUREMENTS_MAPBOX,
  SET_COPY_COORDINATES_MAPBOX,
  SET_GET_FIELDS_COLORS_MAPBOX
} from '../actions/types';

const initialState = {
  isMeasureCoord: false,
  theme: 'light',
  showRoadNames: false,
  showFieldNames: true,
  getMeasurements: false,
  copyCoordinates: false,
  fieldColor: true
};

export const mapTools = (state = initialState, action: any) => {
  switch (action.type) {
    case MEASURE_COORD_MODE_ACTIVE:
      return {
        ...state,
        isMeasureCoord: action.payload
      };
    case SET_MAPBOX_THEME:
      return {
        ...state,
        theme: action.payload.theme
      };
    case SET_SHOW_MAPBOX_ROAD_NAMES:
      return {
        ...state,
        showRoadNames: action.payload.showRoadNames
      };
    case SET_GET_FIELDS_COLORS_MAPBOX:
      return {
        ...state,
        fieldColor: action.payload.fieldColor
      };
    case SET_SHOW_MAPBOX_FIELD_NAMES:
      return {
        ...state,
        showFieldNames: action.payload.showFieldNames
      };
    case SET_GET_MEASUREMENTS_MAPBOX:
      return {
        ...state,
        getMeasurements: action.payload.getMeasurements
      };
    case SET_COPY_COORDINATES_MAPBOX:
      return {
        ...state,
        copyCoordinates: action.payload.copyCoordinates
      };
    default:
      return state;
  }
};
