export const PRODUCT_TYPES = [
  'ACARICIDE',
  'ACARICIDE(MICROBIOLOGICAL)',
  'ACARICIDE/ADHESIVE(SPREADER)/ADJUVANT/INSECTICIDE',
  'ACARICIDE/ADJUVANT/INSECTICIDE',
  'ACARICIDE/ADJUVANT/INSECTICIDE/NEMATICIDE',
  'ACARICIDE/ATTRACTANT/INSECTICIDE',
  'ACARICIDE/BACTERICIDE',
  'ACARICIDE/BACTERICIDE/FUNGICIDE',
  'ACARICIDE/BACTERICIDE/FUNGICIDE/INSECTICIDE',
  'ACARICIDE/BACTERICIDE/FUNGICIDE/INSECTICIDE/SEED-TREATMENT',
  'ACARICIDE/FORMICIDE/INSECTICIDE',
  'ACARICIDE/FUMIGANT',
  'ACARICIDE/FUNGICIDE',
  'ACARICIDE/FUNGICIDE/INSECTICIDE',
  'ACARICIDE/FUNGICIDE/INSECTICIDE/NEMATICIDE/RODENTICIDE',
  'ACARICIDE/GROWTH-REGULATOR/INSECTICIDE',
  'ACARICIDE/HERBICIDE/NEMATICIDE',
  'ACARICIDE/INSECT-GROWTH-REGULATOR/INSECTICIDE',
  'ACARICIDE/INSECTICIDE',
  'ACARICIDE/INSECTICIDE(MICROBIOLOGICAL)',
  'ACARICIDE/INSECTICIDE/INSECT-REPELLENT/MOLLUSCICIDE',
  'ACARICIDE/INSECTICIDE/MOLLUSCICIDE',
  'ACARICIDE/INSECTICIDE/NEMATICIDE',
  'ACARICIDE/INSECTICIDE/PROD.-AGAINST-STORAGE-PESTS',
  'ACARICIDE/INSECTICIDE/RODENTICIDE',
  'ACARICIDE/INSECTICIDE/SEED-PROTECTANT',
  'ACARICIDE/INSECTICIDE/SEED-TREATMENT',
  'ACARICIDE/MOLLUSCICIDE/TADPOLE-SH',
  'ACARICIDE/REPELLENT',
  'ACRIDICIDE',
  'ACRIDICIDE/INSECTICIDE',
  'ACTIVATOR',
  'ADHESIVE(SPREADER)',
  'ADHESIVE(SPREADER)/ADJUVANT',
  'ADHESIVE(SPREADER)/ADJUVANT/FUNGICIDE',
  'ADJUVANT',
  'ADJUVANT/ACARICIDE/FUNGICIDE/INSECTICIDE',
  'ADJUVANT/FUNGICIDE/INSECTICIDE',
  'ADJUVANT/GROWTH-REGULATOR',
  'ADJUVANT/HERBICIDE',
  'ADJUVANT/HERBICIDE/SURFACTANT',
  'ADJUVANT/INSECTICIDE',
  'ADJUVANT/SURFACTANT',
  'ALGAECIDE',
  'ALGAECIDE/FUNGICIDE',
  'ALGAECIDE/FUNGICIDE/SLIMICIDE',
  'ALGAECIDE/HERBICIDE',
  'ALGAECIDE/HERBICIDE/MOLLUSCICIDE',
  'ALGAECIDE/SANITIZER/SLIMICIDE/BACTERICIDE',
  'ALGAECIDE/SLIMICIDE',
  'ALGICIDE',
  'ANIMAL-TOXICANT',
  'ANIMAL-TOXICANT/INSECTICIDE/RODENTICIDE',
  'ANIMAL-TOXICANT/RODENTICIDE',
  'ANTI-FOAMING',
  'ANTI-FOULING-PAINT',
  'ANTI-MOSS',
  'ANTI-MOSS/HERBICIDE',
  'ANTI-SCALD',
  'ANTI-SPROUTING',
  'ATTRACTANT',
  'ATTRACTANT/INSECTICIDE',
  'AVICIDE',
  'BACTERICIDE',
  'BACTERICIDE/DISINFECTANT/FUNGICIDE',
  'BACTERICIDE/FUMIGANT/FUNGICIDE',
  'BACTERICIDE/FUNGICIDE',
  'BACTERICIDE/FUNGICIDE(MICROBIOLOGICAL)',
  'BACTERICIDE/FUNGICIDE/GROWTH-REGULATOR',
  'BACTERICIDE/FUNGICIDE/INSECTICIDE',
  'BACTERICIDE/FUNGICIDE/MOLLUSCICIDE',
  'BACTERICIDE/FUNGICIDE/NEMATICIDE',
  'BACTERICIDE/FUNGICIDE/NEMATICIDE/SOIL-DISINFECTANT',
  'BACTERICIDE/FUNGICIDE/SEED-TREATMENT',
  'BACTERICIDE/FUNGICIDE/SOIL-DISINFECTANT',
  'BACTERICIDE/FUNGICIDE/SPORICIDE',
  'BACTERICIDE/FUNGICIDE/VIRUCIDE',
  'BACTERICIDE/GROWTH-REGULATOR',
  'BACTERICIDE/INSECTICIDE',
  'BACTERICIDE/NEMATICIDE',
  'BAIT',
  'BIO-FUNGICIDE',
  'BIO-INSECTICIDE',
  'BIO-NEMATICIDE',
  'BIOCHEMICAL-PESTICIDE',
  'BIOLOGICAL-CONTROL-AGENT',
  'BIOLOGICAL-CONTROL-PRODUCT',
  'BIOLOGICAL-CTRL.-PROD./INSECTICIDE',
  'BIOLOGICAL-CTRL.-PROD./NEMATICIDE',
  'BIOLOGICAL-CTRL.-PROD./VIRUCIDE',
  'BIOSTIMULANT',
  'BIRD-REPELLENT',
  'BUFFER-AGENT',
  'CROP-BACTERICIDE',
  'CROP-BACTERICIDE/FUNGICIDE',
  'CROP-BACTERICIDE/FUNGICIDE/HERBICIDE',
  'CRUSTICIDE',
  'CRUSTICIDE/MOLLUSCICIDE',
  'DEFOLIANT',
  'DEFOLIANT/DESICCANT',
  'DEFOLIANT/DESICCANT/HERBICIDE',
  'DEFOLIANT/DESICCANT/REGULATOR',
  'DEFOLIANT/GROWTH-REGULATOR(PGR)',
  'DEFOLIANT/HERBICIDE',
  'DEFOLIANT/HERBICIDE/REGULATOR',
  'DEFOLIANT/REGULATOR',
  'DESICCANT',
  'DESICCANT/HERBICIDE',
  'DESICCANT/HERBICIDE/REGULATOR',
  'DESICCANT/MICROBIAL/REGULATOR',
  'DISINFECTANT',
  'DISINFECTANT/FUNGICIDE/HERBICIDE/NEMATICIDE',
  'DISINFECTANT/FUNGICIDE/NEMATICIDE',
  'DISINFECTANT/INSECTICIDE',
  'DISINFECTANT/PRESERVATIVE',
  'DISINFECTANT/SEED-TREATMENT',
  'ELICITOR',
  'FERTILIZER/SOIL-CONDITIONER',
  'FORMICIDE',
  'FORMICIDE/FUNGICIDE',
  'FORMICIDE/FUNGICIDE/INSECTICIDE/NEMATICIDE',
  'FORMICIDE/INSECTICIDE',
  'FORMICIDE/INSECTICIDE/RODENTICIDE',
  'FORMICIDE/INSECTICIDE/SEED-TREATMENT',
  'FORMICIDE/INSECTICIDE/TERMITICIDE',
  'FUMIGANT',
  'FUMIGANT/FUNGICIDE',
  'FUMIGANT/INSECTICIDE',
  'FUMIGANT/INSECTICIDE/MOLLUSCICIDE/TADPOLE-SH',
  'FUMIGANT/INSECTICIDE/RODENTICIDE',
  'FUMIGANT/NEMATICIDE',
  'FUMIGANT/SEED-TREATMENT',
  'FUNGICIDE',
  'FUNGICIDE(MICROBIOLOGICAL)',
  'FUNGICIDE/ACARICIDE',
  'FUNGICIDE/DISINFECTANT/PRESERVATIVE',
  'FUNGICIDE/GROWTH-REGULATOR',
  'FUNGICIDE/GROWTH-REGULATOR/HERBICIDE',
  'FUNGICIDE/GROWTH-REGULATOR/INSECTICIDE',
  'FUNGICIDE/GROWTH-REGULATOR/SEED-PROTECTANT',
  'FUNGICIDE/HERBICIDE',
  'FUNGICIDE/HERBICIDE/INSECTICIDE',
  'FUNGICIDE/HERBICIDE/INSECTICIDE/NEMATICIDE',
  'FUNGICIDE/HERBICIDE/INSECTICIDE/NEMATICIDE/RODENTICIDE',
  'FUNGICIDE/HERBICIDE/INSECTICIDE/NEMATICIDE/SOIL-FUMIGANT',
  'FUNGICIDE/HERBICIDE/INSECTICIDE/SOIL-FUMIGANT',
  'FUNGICIDE/HERBICIDE/INSECTICIDE/VIRUCIDE',
  'FUNGICIDE/HERBICIDE/INSECTICIDE/WOOD-PRESERVATIVE',
  'FUNGICIDE/HERBICIDE/MOLLUSCICIDE',
  'FUNGICIDE/HERBICIDE/MOLLUSCICIDE/NEMATICIDE/TADPOLE-SH',
  'FUNGICIDE/HERBICIDE/MOLLUSCICIDE/TADPOLE-SH',
  'FUNGICIDE/HERBICIDE/NEMATICIDE',
  'FUNGICIDE/HERBICIDE/NEMATICIDE/SLIMICIDE',
  'FUNGICIDE/HERBICIDE/NEMATICIDE/SOIL-FUMIGANT',
  'FUNGICIDE/HERBICIDE/NEMATICIDE/WOOD-PRESERVATIVE',
  'FUNGICIDE/HERBICIDE/REGULATOR',
  'FUNGICIDE/HERBICIDE/WOOD-PRESERVATIVE',
  'FUNGICIDE/INSECT-GROWTH-REGULATOR/INSECTICIDE',
  'FUNGICIDE/INSECTICIDE',
  'FUNGICIDE/INSECTICIDE/MICROBIAL',
  'FUNGICIDE/INSECTICIDE/MICROBIAL/REGULATOR',
  'FUNGICIDE/INSECTICIDE/MISCELLANEOUS',
  'FUNGICIDE/INSECTICIDE/MOLLUSCICIDE/NEMATICIDE',
  'FUNGICIDE/INSECTICIDE/NEMATICIDE',
  'FUNGICIDE/INSECTICIDE/NEMATICIDE/SOIL-FUMIGANT',
  'FUNGICIDE/INSECTICIDE/REGULATOR',
  'FUNGICIDE/INSECTICIDE/REPELLENT',
  'FUNGICIDE/INSECTICIDE/RODENTICIDE',
  'FUNGICIDE/INSECTICIDE/SEED-PROTECTANT',
  'FUNGICIDE/INSECTICIDE/SEED-TREATMENT',
  'FUNGICIDE/INSECTICIDE/VIRUCIDE',
  'FUNGICIDE/INSECTICIDE/WOOD-PRESERVATIVE',
  'FUNGICIDE/MATERIAL-PRESERVATIVE',
  'FUNGICIDE/MICROBIAL',
  'FUNGICIDE/MICROBIAL/REGULATOR',
  'FUNGICIDE/MOLLUSCICIDE',
  'FUNGICIDE/NEMATICIDE',
  'FUNGICIDE/NEMATICIDE(MICROBIOLOGICAL)',
  'FUNGICIDE/NEMATICIDE/PLANT-GROWTH-REGULATOR',
  'FUNGICIDE/NEMATICIDE/SOIL-FUMIGANT',
  'FUNGICIDE/REGULATOR',
  'FUNGICIDE/REPELLENT',
  'FUNGICIDE/SANITIZER',
  'FUNGICIDE/SEED-PROTECTANT',
  'FUNGICIDE/SEED-TREATMENT',
  'FUNGICIDE/SEED-TREATMENT/BIO-FERTILIZER',
  'FUNGICIDE/SPORICIDE/TUBERCULOCIDE/VIRUCIDE',
  'FUNGICIDE/TUBERCULOCIDE/VIRUCIDE',
  'FUNGICIDE/VIRUCIDE',
  'FUNGICIDE/WOOD-PRESERVATIVE',
  'FUNGISTAT',
  'GROWTH-REGULATOR(PGR)',
  'GROWTH-REGULATOR(PGR)/WOUND-TREATMENT',
  'GROWTH-REGULATOR/GROWTH-STIMULANT',
  'GROWTH-REGULATOR/HERBICIDE',
  'GROWTH-REGULATOR/HERBICIDE/INSECTICIDE',
  'GROWTH-REGULATOR/INSECTICIDE',
  'GROWTH-REGULATOR/NEMATICIDE',
  'GROWTH-REGULATOR/REPELLENT',
  'GROWTH-REGULATOR/SEED-PROTECTANT',
  'GROWTH-STIMULANT',
  'GROWTH-STIMULANT/NEMATICIDE',
  'HERBICIDE',
  'HERBICIDE-W/FERTILIZER',
  'HERBICIDE/INSECTICIDE',
  'HERBICIDE/INSECTICIDE/ACATICIDE',
  'HERBICIDE/INSECTICIDE/NEMATICIDE',
  'HERBICIDE/INSECTICIDE/NEMATICIDE/SOIL-FUMIGANT',
  'HERBICIDE/MICROBIAL-PESTICIDE',
  'HERBICIDE/REGULATOR',
  'HERBICIDE/SAFENER',
  'IMMUNITY-STIMULATOR',
  'INOCULANT',
  'INSECT-GROWTH-REGULATOR',
  'INSECT-GROWTH-REGULATOR/INSECTICIDE',
  'INSECT-GROWTH-REGULATOR/INSECTICIDE/NEMATICIDE',
  'INSECT-GROWTH-REGULATOR/INSECTICIDE/REPELLENT',
  'INSECT-GROWTH-REGULATOR/REPELLENT',
  'INSECT-GROWTH-REGULATOR/SEX-ATTRACTANT',
  'INSECT-REPELLENT',
  'INSECTICIDE',
  'INSECTICIDE-SYNERGIST/SAFENER',
  'INSECTICIDE(MICROBIOLOGICAL)',
  'INSECTICIDE(SOAP)',
  'INSECTICIDE(SOAP)/INSECTICIDE',
  'INSECTICIDE/CRUSTICIDE',
  'INSECTICIDE/MATING-DISRUPTANT',
  'INSECTICIDE/MICROBIAL-PESTICIDE',
  'INSECTICIDE/MOLLUSCICIDE',
  'INSECTICIDE/MOLLUSCICIDE/REGULATOR/TADPOLE-SH',
  'INSECTICIDE/MOLLUSCICIDE/TADPOLE-SH',
  'INSECTICIDE/NEMATICIDE',
  'INSECTICIDE/NEMATICIDE/REPELLENT',
  'INSECTICIDE/NEMATICIDE/SEED-TREATMENT',
  'INSECTICIDE/NEMATICIDE/SEX-ATTRACTANT',
  'INSECTICIDE/NEMATICIDE/SOIL-FUMIGANT',
  'INSECTICIDE/PHEROMONE',
  'INSECTICIDE/REPELLENT',
  'INSECTICIDE/RODENTICIDE',
  'INSECTICIDE/SEED-TREATMENT',
  'INSECTICIDE/TERMITICIDE',
  'INSECTICIDE/VERMIN-DESTROYER',
  'INSECTICIDE/VIRUCIDE',
  'INSECTICIDE/WOOD-PRESERVATIVE',
  'LIMACIDE',
  'LIVING-ORGANISMS',
  'LIVING-ORGANISMS(AGAINST-FUNGI)',
  'LIVING-ORGANISMS(AGAINST-FUNGI)/FUNGICIDE',
  'LIVING-ORGANISMS(BACTERIA)/FUNGICIDE',
  'LIVING-ORGANISMS(FUNGI)',
  'LIVING-ORGANISMS(FUNGI)/FUNGICIDE',
  'LIVING-ORGANISMS(INSECTS)',
  'LIVING-ORGANISMS(INSECTS)/PRESERVATIVE',
  'LIVING-ORGANISMS(MITES)',
  'LIVING-ORGANISMS(NEMATODES)',
  'LIVING-ORGANISMS(NEMATODES)/INSECTICIDE',
  'MATING-DISRUPTANT',
  'MATING-DISRUPTANT/SEX-ATTRACTANT',
  'MICROBIAL-PESTICIDE',
  'MICROBIAL-PESTICIDE/MOLLUSCICIDE/TADPOLE-SH',
  'MICROBIAL-PESTICIDE/NEMATICIDE',
  'MICROBIAL-PESTICIDE/REGULATOR',
  'MICROBIAL-PESTICIDE/SEX-ATTRACTANT',
  'MICRONUTRIENTS',
  'MISCELLANEOUS',
  'MOLLUSCICIDE',
  'MOLLUSCICIDE/REPELLENT',
  'MOLLUSCICIDE/SLIMICI',
  'MOLLUSCICIDE/TADPOLE-SH',
  'NATURAL-DEFENCE-STIMULATOR',
  'NEMATICIDE',
  'NEMATICIDE(MICROBIOLOGICAL)',
  'NEMATICIDE/REGULATOR',
  'NEMATICIDE/SEX-ATTRACTANT',
  'NEMATICIDE/SOIL-FUMIGANT',
  'NON-PARASITIC-PLANT-DISEASE-CONTROL',
  'ORGANISMS(INSECT-VIRUSES)',
  'OTHER',
  'OTHERS',
  'PESTICIDE/FERTILIZER',
  'PHEROMONE',
  'PHEROMONE-SYNTHETIC',
  'PHYSICAL-CONTROL-AGENT',
  'PLANT GROWTH REGULATO',
  'PLANT-ACTIVATOR',
  'PRESERVATIVE',
  'PRESERVATIVE/INSECTICIDE',
  'PRODUCT-AGAINST-STORAGE-PESTS',
  'PROTECTANT',
  'PRUNING-PAINT',
  'REGULATOR',
  'REPELLENT',
  'RODENT/REPELLENT',
  'RODENTICIDE',
  'SAFENER',
  'SEED-TREATMENT',
  'SEED-PROTECTANT',
  'SEED-TREATMENT',
  'SOIL-DISINFECTANT',
  'SOIL-DISINFECTANT/NEMATICIDE',
  'SOIL-FUMIGANT',
  'SOIL-STERILANT',
  'SPECIAL-PREPARATIONS',
  'SPORICIDE',
  'SPORICIDE/VIRUCIDE',
  'STERILIZING-PLANTING-AGENT',
  'SULPHUR',
  'SURFACTANT/FRUIT-COATING',
  'TALPICIDE',
  'TANK-MIX',
  'TECHNICAL',
  'TERMITICIDE',
  'TUBERCULOCIDE/VIRUCIDE',
  'UNSPECIFIED',
  'VIRUCIDE',
  'WAX',
  'WETTING',
  'WOOD-PRESERVATIVE',
  'WOUND-TREATMENT'
];
