import {
  CREATE_REGION_SUCCESS,
  CREATE_REGION_ERROR,
  REGION_LOADING,
  GET_REGION_TREE_BY_REGION_ID_LOADING,
  GET_REGION_TREE_BY_REGION_ID_SUCCESS,
  GET_REGION_TREE_BY_REGION_ID__ERROR,
  FETCH_REGIONS_BY_PROPERTY_ID_LOADING,
  FETCH_REGIONS_BY_PROPERTY_ID_SUCCESS,
  FETCH_REGIONS_BY_PROPERTY_ID_ERROR,
  RESET_REGION_LIST_DATA,
  GET_MULTIPLE_PROPS_REGIONS_LOADING,
  GET_MULTIPLE_PROPS_REGIONS_ERROR,
  GET_MULTIPLE_PROPS_REGIONS_SUCCESS
} from '../actions/types';

const initialState = {
  loading: false,
  success: false,
  error: null,
  data: {},
  regionTreeByRegionIdLoading: false,
  regionTreeByRegionIdSuccess: false,
  regionTreeByRegionIdError: null,
  regionTreeByRegionIdData: {},
  regionsOfMultiplePropsIdLoading: false,
  regionsOfMultiplePropsSuccess: false,
  regionsOfMultiplePropsError: null,
  regionsOfMultiplePropsData: []
};

export const region = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REGIONS_BY_PROPERTY_ID_SUCCESS:
      const { payload } = action;

      return {
        ...state,
        success: true,
        loading: false,
        data: payload,
        error: {}
      };
    case FETCH_REGIONS_BY_PROPERTY_ID_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        success: false
      };
    case FETCH_REGIONS_BY_PROPERTY_ID_LOADING:
      return {
        ...state,
        data: {},
        loading: true,
        success: false
      };
    case REGION_LOADING:
      return {
        ...initialState,
        loading: true
      };
    case CREATE_REGION_SUCCESS:
      return {
        ...initialState,
        data: action.payload
      };
    case CREATE_REGION_ERROR:
      return {
        ...initialState,
        error: action.error
      };
    case GET_REGION_TREE_BY_REGION_ID_LOADING:
      return {
        ...state,
        regionTreeByRegionIdLoading: true,
        regionTreeByRegionIdSuccess: false,
        regionTreeByRegionIdError: null,
        regionTreeByRegionIdData: {}
      };
    case GET_REGION_TREE_BY_REGION_ID_SUCCESS:
      return {
        ...state,
        regionTreeByRegionIdLoading: false,
        regionTreeByRegionIdSuccess: true,
        regionTreeByRegionIdError: null,
        regionTreeByRegionIdData: action.payload
      };
    case GET_REGION_TREE_BY_REGION_ID__ERROR:
      return {
        ...state,
        regionTreeByRegionIdLoading: false,
        regionTreeByRegionIdSuccess: false,
        regionTreeByRegionIdError: action.error,
        regionTreeByRegionIdData: {}
      };
    case RESET_REGION_LIST_DATA:
      return {
        ...state,
        data: {},
        loading: false,
        success: false
      };
    case GET_MULTIPLE_PROPS_REGIONS_LOADING:
      return {
        ...state,
        regionsOfMultiplePropsLoading: true,
        regionsOfMultiplePropsSuccess: false,
        regionsOfMultiplePropsError: null,
        regionsOfMultiplePropsData: []
      };
    case GET_MULTIPLE_PROPS_REGIONS_SUCCESS:
      return {
        ...state,
        regionsOfMultiplePropsLoading: false,
        regionsOfMultiplePropsSuccess: true,
        regionsOfMultiplePropsError: null,
        regionsOfMultiplePropsData: action.payload
      };
    case GET_MULTIPLE_PROPS_REGIONS_ERROR:
      return {
        ...state,
        regionsOfMultiplePropsLoading: false,
        regionsOfMultiplePropsSuccess: false,
        regionsOfMultiplePropsError: action.error,
        regionsOfMultiplePropsData: []
      };
    default:
      return state;
  }
};
