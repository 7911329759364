import { createBrowserHistory } from 'history';

const PRIVATE_ROUTE_PREFIX_FORWARD = '/app';

export const history = createBrowserHistory();
export const LANDING_ORG_ROUTE = `app/landing-organization`;
export const FARM_ROUTE = 'property';
export const EDIT_FARM_ROUTE = 'edit-farm';

export const URL_CONSTANTS = {
  LOGIN: () => '/login',
  HALL_OF_ORG: () => `${PRIVATE_ROUTE_PREFIX_FORWARD}/my-organizations`,
  HALL_OF_ENTITIES: () => `${PRIVATE_ROUTE_PREFIX_FORWARD}/my-cropwise`,
  TERMS_AND_CONDITIONS: () => '/terms-and-conditions',
  RESOURCES: ({ orgId, type }: WithOrgId & { type: string }) => {
    const typePath = `/${type}`;
    return `/${LANDING_ORG_ROUTE}/${orgId}/resources${type ? typePath : ''}`;
  },
  FERTILIZERS: ({ orgId, type }: WithOrgId & { type: string }) => {
    return `/${LANDING_ORG_ROUTE}/${orgId}/resources/${type}/seeds-variety`;
  },
  INPUTS_GENERIC_ITEMS: ({ orgId }: WithOrgId) => {
    return `/${LANDING_ORG_ROUTE}/${orgId}/inputs/generic-items`;
  },
  INPUTS_SEEDS_VARIETY: ({ orgId }: WithOrgId) => {
    return `/${LANDING_ORG_ROUTE}/${orgId}/inputs/seeds-variety`;
  },
  INPUTS_FERTILIZERS: ({ orgId }: WithOrgId) => {
    return `/${LANDING_ORG_ROUTE}/${orgId}/inputs/fertilizers`;
  },
  INPUTS_CROP_PROTECTION: ({ orgId }: WithOrgId) => {
    return `/${LANDING_ORG_ROUTE}/${orgId}/inputs/crop-protection`;
  },
  LANDING_ORGANIZATION: ({ orgId }: WithOrgId) => `/${LANDING_ORG_ROUTE}/${orgId}`,
  CREATE_FARM: ({ orgId }: WithOrgId) => `/${LANDING_ORG_ROUTE}/${orgId}/create-farm`,
  EDIT_FARM: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/edit-farm/${farmId}`,
  ALL_FIELDS: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/all-fields`,
  CREATE_FIELDS: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-fields`,
  CREATE_FIELD_SHAPE_FILE_UPLOADER: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/uploader`,
  CREATE_FIELD_DRAW: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/draw-field`,
  CREATE_DETECT_FIELD: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/detect-fields`,
  EDIT_FIELD_DRAW: ({ orgId, farmId, fieldId }: WithOrgAndFarm & { fieldId: string }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/edit-field/${fieldId}`,
  EDIT_FIELD_DRAW_UPLOADED: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/edit-field/upload`,
  EDIT_FIELD_DRAW_DETECT_FIELDS: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/edit-field/detect-fields`,
  EDIT_SUB_REGION_FIELD_DRAW_DETECT_FIELDS: ({
    orgId,
    farmId,
    parentRegionId
  }: WithOrgFarmAndParentRegionId) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/edit-field/detect-fields`,
  CREATE_SUB_REGION_FIELD_SHAPE_FILE_UPLOADER: ({
    orgId,
    farmId,
    parentRegionId
  }: WithOrgFarmAndParentRegionId) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-field/uploader`,
  CREATE_SUB_REGION_FIELD_SHAPE_FILE_LIST: ({
    orgId,
    farmId,
    parentRegionId
  }: WithOrgFarmAndParentRegionId) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-field/uploaded-list`,
  EDIT_SUB_REGION_FIELD_DRAW_UPLOADED: ({
    orgId,
    farmId,
    parentRegionId
  }: WithOrgFarmAndParentRegionId) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/edit-field/upload`,
  EDIT_SUB_REGION_FIELD_DRAW: ({
    orgId,
    farmId,
    parentRegionId,
    fieldId
  }: WithOrgFarmAndParentRegionId & { fieldId: string }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/edit-field/${fieldId}`,
  CREATE_SUB_REGION_FIELD_DRAW: ({ orgId, farmId, parentRegionId }: WithOrgFarmAndParentRegionId) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-field/draw-field`,
  CREATE_SUB_REGION_DETECT_FIELD: ({
    orgId,
    farmId,
    parentRegionId
  }: WithOrgFarmAndParentRegionId) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-field/detect-fields`,
  LANDING_PROPERTY_TABLE_VIEW: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/table-view`,
  UPLOAD_FILE_DEEP_ACTION: ({ callbackUri, orgId, farmId }: WithOrgFarmAndCallbackUri) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/upload-file?org_id=${orgId}&property_id=${farmId}&callback_uri=${callbackUri}`,
  DRAW_FIELD_DEEP_ACTION: ({ callbackUri, orgId, farmId }: WithOrgFarmAndCallbackUri) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/draw-field?org_id=${orgId}&property_id=${farmId}&callback_uri=${callbackUri}`,
  DETECT_FIELD_DEEP_ACTION: ({ callbackUri, orgId, farmId }: WithOrgFarmAndCallbackUri) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/detect-field?org_id=${orgId}&property_id=${farmId}&callback_uri=${callbackUri}`,
  EDIT_FIELD_DEEP_ACTION: ({
    callbackUri,
    orgId,
    farmId,
    fieldId
  }: WithOrgFarmAndCallbackUri & { fieldId: string }) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/edit-field?org_id=${orgId}&property_id=${farmId}&field_id=${fieldId}&callback_uri=${callbackUri}`,
  CREATE_FIELD_DEEP_ACTION: ({ callbackUri, orgId, farmId }: WithOrgFarmAndCallbackUri) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/create-field?org_id=${orgId}&property_id=${farmId}&callback_uri=${callbackUri}`,
  AVAILABLE_PRODUCTS_DEEP_ACTION: ({
    workspaceId,
    confirmationMethod,
    callbackUri
  }: WithWSCallbackUri & { confirmationMethod: string }) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/available-products?workspace=${workspaceId}&confirmation_method=${confirmationMethod}&callback_uri=${callbackUri}`,
  INVITE_MEMBERS_DEEP_ACTION: ({
    callbackUri,
    workspaceId,
    inviteLevel
  }: WithWSCallbackUri & { inviteLevel: string }) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/invite-members?workspace=${workspaceId}&callback_uri=${callbackUri}&invite_level=${inviteLevel}`,
  EDIT_MEMBERS_DEEP_ACTION: ({
    callbackUri,
    workspaceId,
    inviteLevel
  }: WithWSCallbackUri & { inviteLevel: string }) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/edit-members?workspace=${workspaceId}&callback_uri=${callbackUri}&invite_level=${inviteLevel}`,
  EDIT_MEMBERS_LEGACY_DEEP_ACTION: ({ callbackUri, workspaceId }: WithWSCallbackUri) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/edit-members-legacy?workspace=${workspaceId}&callback_uri=${callbackUri}`,
  REVIEW_INVITE_DEEP_ACTION: ({ callbackUri, workspaceId }: WithWSCallbackUri) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/review-invite?workspace=${workspaceId}&callback_uri=${callbackUri}`,
  REVIEW_INVITE_LEGACY_DEEP_ACTION: ({ callbackUri, workspaceId }: WithWSCallbackUri) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/review-invite-legacy?workspace=${workspaceId}&callback_uri=${callbackUri}`,
  CREATE_REGION_DRAW: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-region/draw-region`,
  CREATE_SUB_REGION_DRAW: ({ orgId, farmId, parentRegionId }: WithOrgFarmAndParentRegionId) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-region/draw-region`,
  EDIT_SUB_REGION_DRAW: ({
    orgId,
    farmId,
    parentRegionId,
    regionId
  }: WithOrgFarmAndParentRegionId & { regionId: string }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/edit-region/${regionId}`,
  CREATE_REGION_SHAPE_FILE_UPLOADER: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-region/uploader`,
  CREATE_SUB_REGION_SHAPE_FILE_UPLOADER: ({
    orgId,
    farmId,
    parentRegionId
  }: WithOrgFarmAndParentRegionId) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-region/uploader`,
  IMPORT_ATTR_IN_REGION_UPLOADER: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-region/import-attributes`,
  IMPORT_ATTR_IN_SUB_REGION_UPLOADER: ({
    orgId,
    farmId,
    parentRegionId
  }: WithOrgFarmAndParentRegionId) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-region/import-attributes`,
  CREATE_REGION_SHAPE_FILE_LIST: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-region/uploaded-list`,
  CREATE_SUB_REGION_SHAPE_FILE_LIST: ({
    orgId,
    farmId,
    parentRegionId
  }: WithOrgFarmAndParentRegionId) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-region/uploaded-list`,
  EDIT_REGION_DRAW_UPLOADED: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/edit-region/upload`,
  EDIT_SUB_REGION_DRAW_UPLOADED: ({
    orgId,
    farmId,
    parentRegionId
  }: WithOrgFarmAndParentRegionId) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/edit-region/upload`,
  MANAGE_REGIONS: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/regions/manage-regions`,
  FIELD_TIMELINE: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/timeline`,
  FIELD_CROP_ROTATIONS: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/crop-rotations`,
  ORG_SEASONS: ({ orgId }: WithOrgId) => `/${LANDING_ORG_ROUTE}/${orgId}/org-seasons`,
  ARCHIVED_FIELDS: ({ orgId, farmId }: WithOrgAndFarm) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/archived-fields`
};

interface WithWSId {
  workspaceId: string;
}
interface WithOrgId {
  orgId: string;
}
interface WithFarmId {
  farmId: string;
}
interface WithCallbackUri {
  callbackUri: string;
}

type WithOrgAndFarm = WithOrgId & WithFarmId;
type WithOrgFarmAndParentRegionId = WithOrgAndFarm & { parentRegionId: string };
type WithOrgFarmAndCallbackUri = WithOrgAndFarm & WithCallbackUri;
type WithWSCallbackUri = WithWSId & WithCallbackUri;

export const publicItems = ['/', '/login', '/authenticate'];
