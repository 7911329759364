import geojson2svg from 'geojson-to-svg';
import gjvv from 'geojson-validation';

export interface IGeoProps {
  geojson: any;
  fill?: string;
  stroke?: string;
  weight?: string;
  width?: string;
  height?: string;
  extraStyles?: object;
}

export const GeoJsonToSvg = (props: IGeoProps) => {
  const {
    geojson,
    fill = '#C2C7D0',
    stroke = '#C2C7D0',
    weight = 0.00009,
    width = '20px',
    height = '20px',
    extraStyles
  } = props;

  if ('properties' in geojson) {
    geojson.properties.thumb = 'basic';
  } else {
    geojson.properties = {
      thumb: 'basic'
    };
  }

  const inputData = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        ...geojson
      }
    ]
  };

  if (inputData && gjvv.valid(inputData)) {
    const image = geojson2svg()
      .type('thumb')
      .styles({ basic: { fill, stroke, weight } })
      .data(inputData)
      .render();

    return (
      <img
        width={width}
        height={height}
        style={{ transform: 'scaleY(-1)', ...extraStyles }}
        src={`data:image/svg+xml;base64,${btoa(image)}`}
      />
    );
  } else {
    return (
      <>
        <div style={{ width, height, backgroundColor: stroke }} />
      </>
    );
  }
};
