import { Neutral, Red } from 'app/theme';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  border-radius: 12px;
  background-color: ${Neutral[80]};

  position: absolute;
  transform: translateX(-50%);
  left: 50%;

  &.floating-bulk-actions-exit,
  &.floating-bulk-actions-enter-done {
    bottom: 24px;
  }

  &.floating-bulk-actions-exit-active {
    bottom: -108px;
    transition: bottom 300ms ease-in;
  }

  &.floating-bulk-actions-enter {
    bottom: -108px;
  }

  &.floating-bulk-actions-enter-active {
    transition: bottom 300ms ease-out;
    bottom: 24px;
  }
`;

export const InnerContainer = styled.div`
  border-radius: 12px;
  background-color: ${Neutral[90]};
  padding: 12px;

  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${Neutral[0]};

  .title,
  .close {
    color: ${Neutral[40]};
  }

  .title {
    margin-right: 24px;
  }

  .close {
    cursor: pointer;
    margin-left: 4px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const Button = styled.button<{ $danger?: boolean; $disabled?: boolean }>`
  cursor: pointer;
  background: none;
  outline: none;
  border: 1px solid ${Neutral[0]};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 32px;
  transition: opacity 300ms ease-in-out;
  color: ${Neutral[0]};

  svg,
  path {
    fill: ${Neutral[0]};
    color: ${Neutral[0]};
  }

  .icon {
    display: flex;
    align-items: center;
    margin-right: 4px;
  }

  & + & {
    margin-left: 12px;
  }

  ${({ $danger }) =>
    $danger &&
    css`
      margin-left: 32px !important;
      color: ${Red[50]};
      border-color: ${Red[50]};

      svg,
      path {
        fill: ${Red[50]};
        color: ${Red[50]};
      }
    `}

  ${({ $disabled }) =>
    $disabled
      ? css`
          cursor: not-allowed;
          color: ${Neutral[70]};
          border-color: ${Neutral[70]};

          svg,
          path {
            color: ${Neutral[70]};
            fill: ${Neutral[70]};
          }
        `
      : css`
          :hover {
            opacity: 0.6;
          }
        `}
`;
