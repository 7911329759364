import { getFeatureFlags } from '../actions/rollout';
import { SETUP_ROLLOUT, SETUP_ROLLOUT_SUCCESS, SETUP_ROLLOUT_ERROR } from '../actions/types';

const initialState = {
  loading: true,
  error: null,
  flags: getFeatureFlags()
};

export const rollout = (state = initialState, action) => {
  switch (action.type) {
    case SETUP_ROLLOUT:
      return {
        ...state,
        loading: true
      };
    case SETUP_ROLLOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        flags: action.payload
      };
    case SETUP_ROLLOUT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};
