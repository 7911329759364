import { request } from '../../utils/axios';
import { getHeaders } from '../../utils/commonMethods';
import {
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_ERROR,
  FETCH_ORGANIZATIONS_LOADING,
  SET_SELECTED_ORG,
  TOGGLE_HEADER,
  TOGGLE_DEEP_ACTION,
  SET_SELECTED_SEASONS,
  DELETE_ORGANIZATION_LOADING,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_ERROR,
  SET_WHATS_NEW_ISVISIBLE,
  HAVE_CONTRACT_FOR_ORG,
  SET_CONTRACT_FOR_ORG
} from './types';

function fetchOrganizationsLoading() {
  return {
    type: FETCH_ORGANIZATIONS_LOADING
  };
}

function fetchOrgsSuccess(orgs) {
  return {
    type: FETCH_ORGANIZATIONS_SUCCESS,
    payload: orgs
  };
}
function fetchOrgsError(error) {
  return {
    type: FETCH_ORGANIZATIONS_ERROR,
    error
  };
}
export function setSelectedSeasons(seasons) {
  return {
    type: SET_SELECTED_SEASONS,
    payload: seasons
  };
}
export function setCurrentOrgId(org) {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_ORG, payload: org });
  };
}
export const fetchOrgs = () => {
  return (dispatch) => {
    dispatch(fetchOrganizationsLoading());
    return request
      .get(`/v2/orgs?fields=seasons,properties,total_area`, {
        headers: getHeaders()
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(fetchOrgsSuccess(res.data.content));
        return res;
      })
      .catch((error) => {
        dispatch(fetchOrgsError(error));
      });
  };
};

export const toggleDeepAction = (status) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_DEEP_ACTION, payload: status });
  };
};

export const toggleHeader = (status) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_HEADER, payload: status });
  };
};
const deleteOrganizationLoading = () => {
  return {
    type: DELETE_ORGANIZATION_LOADING
  };
};

const deleteOrganizationSuccess = (payload) => {
  return {
    type: DELETE_ORGANIZATION_SUCCESS,
    payload
  };
};

const deleteOrganizationError = (error) => {
  return {
    type: DELETE_ORGANIZATION_ERROR,
    error
  };
};

export const deleteOrganization = (org) => {
  return (dispatch) => {
    dispatch(deleteOrganizationLoading());
    return request({
      method: 'delete',
      url: `/v2/orgs/${org.id}`,
      headers: getHeaders()
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(deleteOrganizationSuccess(org));
        return res;
      })
      .catch((error) => {
        dispatch(deleteOrganizationError(error));
      });
  };
};
const propertyDetailsCache = {};
export const getPropertyDetailsById = async (propId) => {
  if (propertyDetailsCache[propId]) return propertyDetailsCache[propId];

  propertyDetailsCache[propId] = request.get(`/v2/properties/${propId}`, {
    headers: getHeaders()
  });
  return propertyDetailsCache[propId];
};
export const setWhatsNewIsVisible = (isVisible) => {
  return {
    type: SET_WHATS_NEW_ISVISIBLE,
    payload: isVisible
  };
};

export const setHaveContractForOrg = (haveContract) => {
  return {
    type: HAVE_CONTRACT_FOR_ORG,
    payload: haveContract
  };
};

export const setContractForOrg = (Contract) => {
  return {
    type: SET_CONTRACT_FOR_ORG,
    payload: Contract
  };
};
