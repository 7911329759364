import moment from 'moment';

export const isFieldDateIncludesTodaysDate = (startDate, endDate) => {
  if (!startDate) {
    return;
  }

  const hasEndDate = !!endDate;
  const mStartDate = moment(startDate);
  const mToday = moment();

  if (mStartDate.isBefore(mToday)) {
    return !hasEndDate || moment(endDate).isAfter(mToday);
  }

  return false;
};

export const getFieldStatusWithColor = (startDate, endDate) => {
  if (isFieldDateIncludesTodaysDate(startDate, endDate)) {
    return {
      status: 'ACTIVE',
      color: '#14803C'
    };
  } else if (moment(endDate).isBefore(moment())) {
    return {
      status: 'INACTIVE',
      color: '#696F88'
    };
  } else if (moment(startDate).isAfter(moment())) {
    return {
      status: 'FUTURE',
      color: '#0071CD'
    };
  } else {
    return {};
  }
};
