import { WarningOutlined } from '@ant-design/icons';
import { Menu as AntMenu, Layout, Popconfirm, Tooltip } from 'antd';
import { MenuProps as RcMenuProps } from 'rc-menu';
import React, { Component, CSSProperties } from 'react';
import DashboardOutline from './icons/dashboard-outline.icon';
import MenuCollapse from './icons/menu-collapse.icon';
import MenuExpand from './icons/menu-expand.icon';
import './sidebar.style.less';

export type MenuTheme = 'light' | 'dark';

const confirmationPopupDefaultText = {
  confirmPopupTitle: 'You will lose the data entered. Continue?',
  confirmPopupOkText: 'Yes',
  confirmPopupCancelText: 'No'
};

interface IMenuItemProps {
  key: string;
  label?: string;
  icon?: string | JSX.Element;
  disabled?: boolean;
  children?: IMenuItemProps[];
  onClick?: () => void;
  needConfirmation?: boolean;
}

export interface IMenuProps extends RcMenuProps {
  collapsedIcon?: string | JSX.Element;
  expandedIcon?: string | JSX.Element;
  menuItems?: IMenuItemProps[];
  selectedKeys?: string[];
  theme?: MenuTheme;
  versionNumber?: string;
  style?: CSSProperties;
  collapsedMenu?: boolean;
  triggerCloseButton?: JSX.Element;
  triggerExpandButton?: JSX.Element;
  shouldApplyPrefixClass?: boolean;
  commonTransaltions?: any;
  className?: any;
}

export interface IMenuStateProps {
  collapsed: boolean;
  currentSubmenu: string;
  collapsedMenu: boolean;
}

const { Sider } = Layout;
const { SubMenu } = AntMenu;

interface ISidebarSubMenuProps {
  menuItem?: IMenuItemProps;
  getSubmenuIcon: (submenuItem: IMenuItemProps) => any;
  collapsed: boolean;
  commonTransaltions?: any;
}

export const SidebarSubMenu: React.FC<ISidebarSubMenuProps> = ({
  menuItem,
  getSubmenuIcon,
  collapsed,
  commonTransaltions,
  ...other
}) => (
  <SubMenu
    {...other}
    key={menuItem?.key}
    title={collapsed ? undefined : menuItem?.label}
    disabled={menuItem?.disabled}
    icon={
      typeof menuItem?.icon === 'string' ? (
        getSubmenuIcon(menuItem)
      ) : (
        <div className="submenuElementIcon">{menuItem?.icon}</div>
      )
    }
    onTitleClick={menuItem?.onClick}
  >
    {menuItem?.children?.map((submenuItem: IMenuItemProps) =>
      submenuItem.needConfirmation ? (
        <>
          <AntMenu.Item key={submenuItem.key} title="" disabled={submenuItem.disabled}>
            {ConfirmPopup(submenuItem, collapsed, getSubmenuIcon, commonTransaltions)}
          </AntMenu.Item>
        </>
      ) : (
        <>
          <AntMenu.Item
            disabled={submenuItem.disabled}
            key={submenuItem.key}
            title=""
            onClick={submenuItem.onClick}
          >
            {typeof submenuItem.icon === 'string' ? (
              getSubmenuIcon(submenuItem)
            ) : (
              <div className="submenuElementIcon">{submenuItem.icon}</div>
            )}
            <p className="submenuName">{submenuItem.label}</p>
          </AntMenu.Item>
        </>
      )
    )}
  </SubMenu>
);

const ConfirmPopup = (
  menuItem: any,
  isCollapsed: any,
  getSubmenuIcon: any,
  commonTransaltions: any = confirmationPopupDefaultText
) => {
  const { onClick, label, icon } = menuItem;
  return (
    <Popconfirm
      placement="top"
      title={commonTransaltions.confirmPopupTitle}
      icon={<WarningOutlined style={{ fontSize: '19px', top: '7px', color: '#E99921' }} />}
      onConfirm={onClick}
      okText={commonTransaltions.confirmPopupOkText}
      okButtonProps={{ size: 'middle', color: 'red' }}
      cancelButtonProps={{ size: 'middle' }}
      cancelText={commonTransaltions.confirmPopupCancelText}
    >
      <>
        <Tooltip title={isCollapsed && label} placement="right" mouseLeaveDelay={0}>
          {typeof icon === 'string' ? (
            getSubmenuIcon(menuItem)
          ) : (
            <div className="submenuElementIcon">{icon}</div>
          )}
        </Tooltip>
        {<p className="submenuName">{label}</p>}
      </>
    </Popconfirm>
  );
};
export default class Sidebar extends Component<IMenuProps> {
  public static getDerivedStateFromProps(props: IMenuProps, state: IMenuStateProps) {
    if (
      'collapsedMenu' in props &&
      props.collapsedMenu !== state.collapsed &&
      props.collapsedMenu !== state.collapsedMenu
    ) {
      return {
        collapsed: props.collapsedMenu,
        collapsedMenu: props.collapsedMenu
      };
    } else {
      return null;
    }
  }
  public state = {
    collapsed: false,
    currentSubmenu: '1',
    collapsedMenu: false
  };

  public toggleCollapsed = () => {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed
    });
  };

  public onCollapse = (collapsed: boolean) => {
    this.setState({ collapsed });
  };

  public handleSubmenuClick = (e: any) => {
    this.setState({
      currentSubmenu: e.key
    });
  };

  public getTopLogo = () => {
    const { collapsed } = this.state;
    const { collapsedIcon, expandedIcon } = this.props;
    return collapsed ? collapsedIcon : expandedIcon;
  };

  // tslint:disable-next-line: cognitive-complexity
  public render() {
    const {
      triggerExpandButton: propTriggerExpandButton,
      triggerCloseButton: propTriggerCloseButton,
      shouldApplyPrefixClass,
      theme,
      onSelect,
      onDeselect,
      onOpenChange,
      selectedKeys,
      style,
      title,
      menuItems,
      className,
      commonTransaltions,
      versionNumber,
      ...others
    } = this.props;

    const { collapsed, currentSubmenu } = this.state;

    const triggerExpandButton =
      'triggerExpandButton' in this.props ? (
        propTriggerExpandButton
      ) : (
        <MenuExpand className="triggerExpandButton" />
      );
    const triggerCloseButton =
      'triggerCloseButton' in this.props ? (
        propTriggerCloseButton
      ) : (
        <MenuCollapse className="triggerCloseButton" />
      );
    return (
      <Sider
        theme={theme}
        trigger={collapsed ? triggerExpandButton : triggerCloseButton}
        width="280"
        className={className}
        collapsedWidth="64"
        collapsible={true}
        collapsed={collapsed}
        onCollapse={this.onCollapse}
      >
        <div className="CropwiseLogo">{this.getTopLogo()}</div>
        <AntMenu
          {...others}
          onClick={this.handleSubmenuClick}
          onSelect={onSelect}
          onDeselect={onDeselect}
          onOpenChange={onOpenChange}
          selectedKeys={selectedKeys ? selectedKeys : [currentSubmenu]}
          theme={theme}
          className={className}
          mode="inline"
          style={style}
          title={title}
        >
          {menuItems?.map((menuItem) =>
            menuItem.children ? (
              <SidebarSubMenu
                menuItem={menuItem}
                getSubmenuIcon={this.getSubmenuIcon}
                collapsed={collapsed}
                commonTransaltions={commonTransaltions}
              />
            ) : menuItem.needConfirmation ? (
              <>
                <AntMenu.Item disabled={menuItem.disabled} key={menuItem.key} title="">
                  {ConfirmPopup(menuItem, collapsed, this.getSubmenuIcon, commonTransaltions)}
                </AntMenu.Item>
              </>
            ) : (
              <>
                <AntMenu.Item
                  disabled={menuItem.disabled}
                  key={menuItem.key}
                  title=""
                  onClick={menuItem.onClick}
                >
                  <Tooltip
                    overlayClassName="sidebar-tooltip-card"
                    title={collapsed && menuItem.label}
                    placement="right"
                    mouseLeaveDelay={0}
                  >
                    <>
                      {typeof menuItem.icon === 'string' ? (
                        this.getSubmenuIcon(menuItem)
                      ) : (
                        <div className="submenuElementIcon">{menuItem.icon}</div>
                      )}
                      {collapsed ? <></> : <p className="submenuName">{menuItem.label}</p>}
                    </>
                  </Tooltip>
                </AntMenu.Item>
              </>
            )
          )}
        </AntMenu>
        <div className="sidebarFooter">{versionNumber}</div>
      </Sider>
    );
  }

  private getSubmenuIcon(menuItem: IMenuItemProps): JSX.Element {
    if (menuItem.icon === 'CropwiseDefaultIcon') {
      return <DashboardOutline className="submenuIcon" />;
    } else {
      return <></>;
    }
  }
}
