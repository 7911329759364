import { useMemo } from 'react';
import { Button, Modal, Progress } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';
import {
  LimitQuotaModalButton,
  LimitQuotaModalContainer,
  LimitQuotaModalContent,
  LimitQuotaModalHeader,
  LimitQuotaModalInfo,
  LimitQuotaModalInfoText
} from './style';
import { useTranslation } from 'react-i18next';
import { Green } from 'app/theme';

interface ILimitQuotaModalProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  availableValue: number;
  usedValue: number;
  total: number;
}

export const LimitQuotaModal = ({
  isVisible = false,
  setIsVisible,
  availableValue,
  usedValue,
  total
}: ILimitQuotaModalProps) => {
  const { t } = useTranslation();

  const usedPercent = useMemo(() => {
    return (usedValue / total) * 100;
  }, [total, usedValue]);

  return (
    <Modal
      centered
      footer={
        <LimitQuotaModalButton>
          <Button type="primary" onClick={() => setIsVisible(false)} className="cancel-button">
            {t('common.text.got_it')}
          </Button>
        </LimitQuotaModalButton>
      }
      open={isVisible}
      onCancel={() => setIsVisible(false)}
    >
      <LimitQuotaModalContainer>
        <LimitQuotaModalHeader>
          <InfoCircleOutlined /> {t('create_fields.limit_creation.limit_quota_error_modal.title')}
        </LimitQuotaModalHeader>
        <LimitQuotaModalContent>
          {t('create_fields.limit_creation.limit_quota_error_modal.exceeded')}
          <LimitQuotaModalInfo>
            <Progress
              type="circle"
              percent={usedPercent}
              strokeColor={Green[50]}
              format={() => usedValue}
              width={68}
            />
            <LimitQuotaModalInfoText>
              <b>{availableValue}</b>{' '}
              {t('create_fields.limit_creation.limit_quota_error_modal.ha_available')}
              <p>
                {t('create_fields.limit_creation.limit_quota_error_modal.total', {
                  total: total
                })}
              </p>
            </LimitQuotaModalInfoText>
          </LimitQuotaModalInfo>
          {t('create_fields.limit_creation.limit_quota_error_modal.contact')}
        </LimitQuotaModalContent>
      </LimitQuotaModalContainer>
    </Modal>
  );
};
