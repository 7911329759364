import { iff } from '../../utils/iff';
import { isCreatePropertySessionActive } from '../../utils/getShowStepper';
import { URL_CONSTANTS } from '../../utils/history';

const PAGE_NAME = 'CURRENT_REGION_PAGE_NAME';

export function clearRegionPage() {
  sessionStorage.removeItem(PAGE_NAME);
}

/**
 *
 * @param {*} params
 * @returns
 */
export function manageRedirection(args) {
  const { orgId, farmId, parentId, newFields, isFildsAdded, navigate } = args;
  const showStepper = isCreatePropertySessionActive();
  return iff(
    showStepper === true,
    () =>
      navigate(URL_CONSTANTS.MANAGE_REGIONS({ orgId, farmId }), {
        state: {
          parentId,
          newFields,
          isFildsAdded
        }
      }),
    () =>
      navigate(URL_CONSTANTS.MANAGE_REGIONS({ orgId, farmId }), {
        state: {
          parentId,
          newFields,
          isFildsAdded
        }
      })
  );
}
