import { Green, Neutral, Red } from 'app/theme';
import styled from 'styled-components';

export const LimitQuotaModalContainer = styled.div``;

export const LimitQuotaModalHeader = styled.div`
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  font-weight: 600;
  position: relative;

  &:after {
    content: '';
    height: 1px;
    width: calc(100% + 48px);
    background: ${Neutral[30]};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  svg {
    width: 20px;
    height: 20px;
    color: ${Red[60]};
  }
`;

export const LimitQuotaModalContent = styled.div`
  padding: 24px 0 0;
  font-size: 14px;
`;

export const LimitQuotaModalInfo = styled.div`
  margin: 16px 0;
  padding: 18px 24px;
  border-radius: 16px;
  border: 2px solid ${Neutral[20]};

  display: flex;
  gap: 25px;
  align-items: center;
`;

export const LimitQuotaModalInfoText = styled.div`
  font-size: 16px;

  b {
    font-size: 24px;
  }
`;

export const LimitQuotaModalButton = styled.div`
  button {
    background: ${Green[60]};
    height: 40px;

    &:hover {
      background: ${Green[60]};
    }
  }
`;
