/* eslint-disable */
type ArgumentTypes<F extends Function> = F extends (...args: infer A) => unknown ? A : never;
export const debounced = <T extends Function>(func: T, delay: number = 300) => {
  let timer: number;
  return (...args: ArgumentTypes<T>) => {
    const ctx = this;
    if (timer) clearTimeout(timer);
    timer = window.setTimeout(() => {
      func.apply(ctx, args);
    }, delay);
  };
};
/* eslint-enable */
