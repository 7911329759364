import styled from 'styled-components';

export const EditField = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 4px;
  width: max-content;
  padding: 0 4px;

  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;
